import { useI18n } from "src/utils/lni18n";
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { EditTestDataDTO } from '../DomainModels';
import { useAtom } from "jotai";
import { MultipleQuestionService } from "./MultipleQuestionService";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import { Active, DndContext, DragOverEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Question from "./Question";
import { useLocation } from "react-router-dom";

const MultipleQuestion = (props: { data: EditTestDataDTO<string[]>, onChange: (d: string[]) => void }) => {

  const { data, onChange } = props;
  const dialogPortal = useDialog();
  const { languageService: t } = useI18n();
  const service = useMemo(() => new MultipleQuestionService(data.TestData), []);
  const [editVals] = useAtom(service.datatom);

  const {state} = useLocation();

  /* D n D */
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const [active, setActive] = useState<Active | null>(null);


  const dragOver = useCallback((e: DragOverEvent) => {
    if (!e.over) {
      return;
    }
    if (e.active.data.current?.sortable.index === e.over?.data.current?.sortable.index) {
      return;
    }
    service.reorderQuestion(e.active.data.current?.sortable.index, e.over?.data.current?.sortable.index);
  }, [])

  const dragEnd = () => {
    const strings = editVals.map(s => s.html);
    onChange(strings);
  }

  /* end D n D */

  const addQuestion = () => {
    const strings = service.addQuestion();
    onChange(strings);
  }

  const deleteQuestion = (id: string, index: number) => {

    dialogPortal({
      factory: (onSubmit, onCancel) => {
        const dprops: ConfirmationOptions = {
          className: "",
          title: t.getText("delete"),
          message: <div className="preserve-white" >{t.getText("remove.name.question", `${t.getText("question")} ${index}`)}</div>,
          languageService: t,
          show: true,
          onClose: onCancel,
          onSubmit: onSubmit,
          buttons: ConfirmationButtons.YesNo
        }
        return <ConfirmationDialog {...dprops} />
      },
      size: "md"
    }).then((res: boolean) => {
      if (res) {
        const strings = service.deleteQuestion(id);
        onChange(strings);
      }
    });


  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const strings = service.handleChange(e, index);
    if (strings) {
      onChange(strings);
    }
  };

  if (!data) return null;

  return <DndContext
    sensors={sensors}

    onDragStart={({ active }) => {
      setActive(active);
    }}
    onDragOver={dragOver}
    onDragEnd={dragEnd}
    onDragCancel={() => {
      setActive(null);
    }}
  >
    <div>

      <button disabled={state?.readOnly} onClick={addQuestion} type="button" className="mt-5 mb-3 btn btn-small btn-primary">{t.getText("add")}</button>
      <SortableContext items={editVals.map(t => t.id)} id="all_questions">
        {editVals.map((val, i) =>
          <Question key={val.id} draggable={true} index={i} headerText={`${t.getText("question")} ${(i + 1)}`} active={active?.id} deleteQuestion={(id) => deleteQuestion(id, (i + 1))} val={val} canDelete={editVals.length > 2} initialValue={service.startValues[i]} name={`inp_${i}`} onChange={(e) => handleChange(e, i)} />
        )}
      </SortableContext>

      {/* t.getText("question") */}

    </div>
  </DndContext>

}

export default MultipleQuestion;