import { LnCollapseGroup } from "src/components/LnCard/LnCollapseGroup";
import { LinksGroupModel } from "src/coursepart/domain/LinksGroupModel";
import { ReadOnlyLink } from "./ReadOnlyLink";
import LnIcon from "src/components/LnIcon";


export default function ReadOnlyLinkGroup(props: {
    group: LinksGroupModel, closed?: boolean, partId: string
}) {

    const { group, closed } = props;

    const name = <div className="d-flex align-items-center"> <div dangerouslySetInnerHTML={{__html:group.Name}}></div>{group.Links.length > 0 ? <LnIcon name="slides" className="icon-small ml-3" /> : <></>}</div>;

    const panel = <LnCollapseGroup groupName={name} isOpen={false}>

        <div>
            {group.Links.map((item) => (
                <ReadOnlyLink key={item.Id} link={item} />
            ))}
        </div>
    </LnCollapseGroup>;

    return <div id={"g_" + group.Id} role="listitem" key={group.Id} className="mb-4" >{panel}</div>
}