import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import "src/assets/scss/main.scss"

import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import EditLecture from './coursepart/Lecture/EditLecture'
import coursePartService from './coursepart/CoursePartService'
import Lectures from './coursepart/Lecture/Lectures'
import PartInfo from './coursepart/PartInfo'
import Links from './coursepart/Link/Links'
import Files from './coursepart/File/Files'
import Tests from './coursepart/Test/Tests'
import lectureService from './coursepart/Lecture/LectureService'
import lecturePlayerService from './coursepart/Lecture/Player/LecturePlayerService'
import LecturePlayer from './coursepart/Lecture/Player/LecturePlayer'
import screenEditService from './coursepart/Lecture/Player/ScreenAudioEditService'
import AudioEditor from './coursepart/Lecture/Player/waveEdit/AudioEditor'
import { DevTools } from 'jotai-devtools'
import AllCourseparts from './coursepart/AllCourseparts'
import coursePartCollectionService from './coursepart/CoursePartCollectionService'
import EditTest from './coursepart/Test/EditTest'
import testService from './coursepart/Test/TestService'
import { ErrorBoundary } from './components/ErrorBoundary'
import Publish from './coursepart/Publish/Publish'
import Evaluations from './coursepart/Evaluations/Evaluations'
import CoursePartGeneral from './coursepart/General/General'
import courseCollectionService from './course/CourseCollectionService'
import AllCourses from './course/AllCourses'
import courseService from './course/CourseService'
import CourseComponent from './course/CourseComponent'
import CourseGeneral from './course/CourseGeneral'
import CoursePublish from './course/CoursePublish'
import EditTheme from './course/EditTheme'
import CoursePartStart from './coursepart/CoursePartStart'
import PrintPart from './coursepart/Print/PrintPart'
import PrintCourse from './course/PrintCourse'
import Home from './Home/Home'
import SwitchUser from './login/SwitchUser'
import CourseAddons from './course/CourseAddons/CourseAddons'
import * as Sentry from "@sentry/react";
import LecturesDiff from './coursepart/Lecture/Diff/LecturesDiff'
import ViewLectureDiff from './coursepart/Lecture/Diff/ViewLectureDiff'
import FilesDiffMain from './coursepart/File/Diff/FilesDiffMain'
import LinksDiffMain from './coursepart/Link/Diff/LinksDiffMain'
import TestDiffMain from './coursepart/Test/Diff/TestDiffMain'


// const store = getDefaultStore();


if (import.meta.env.PROD) {
    Sentry.init({
        dsn: "https://f34f2c420ade35fbd737f429b331ac33@o4507208376320000.ingest.de.sentry.io/4507973136416848",

        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/produce\.laranara\.se/],
        // Session Replay
        replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        ignoreErrors: [
            "ResizeObserver loop limit exceeded", /^No error$/,
            /__show__deepen/,
            /_avast_submit/,
            /Access is denied/,
            /anonymous function: captureException/,
            /Blocked a frame with origin/,
            /can't redefine non-configurable property "userAgent"/,
            /change_ua/,
            /console is not defined/,
            /cordova/,
            /DataCloneError/,
            /Error: AccessDeny/,
            /event is not defined/,
            /feedConf/,
            /ibFindAllVideos/,
            /myGloFrameList/,
            /SecurityError/,
            /MyIPhoneApp/,
            /snapchat.com/,
            /vid_mate_check is not defined/,
            /win\.document\.body/,
            /window\._sharedData\.entry_data/,
            /window\.regainData/,
            /ztePageScrollModule/
        ],

    });
}
else {
    window.document.title = "DEV " + window.document.title;
}


const lectureLoader = async (partId: string, lectureId: string, getDeleted: boolean) => {
    await coursePartService.loadPart({ partId });
    return lectureService.getLectureData(lectureId, getDeleted)
}

const lecturePlayLoader = async (partId: string, lectureId: string, num: number, getDeleted: boolean) => {
    await lectureLoader(partId, lectureId, getDeleted);
    return lecturePlayerService.setCurrentLecture(lectureId, num);
}

const screenEditLoader = async (partId: string, lectureId: string, num: number, screenId: string, getDeleted: boolean) => {
    await screenEditService.fetchAudio(screenId, lectureId);
    return lecturePlayLoader(partId, lectureId, num, getDeleted)
}

const testLoader = async (partId: string, testId: string) => {
    await coursePartService.loadPart({ partId });
    return testService.getTestData(partId, testId);
}

async function courseLoader(courseId: string, partLoader: () => Promise<any>) {
    await courseService.loadCourse(courseId);
    return partLoader()
}

const lecturesRouter =
    <>
        <Route  path="lectures" element={<Lectures />} ></Route>
        <Route loader={({ params }) => lectureLoader(params.partId!, params.lectureId!, false)} path="lectures/:lectureId" element={<EditLecture />} ></Route>
        <Route loader={({ params }) => lecturePlayLoader(params.partId!, params.lectureId!, +params.num!, false)} path="lectures/:lectureId/:num/:playtype/" element={<LecturePlayer />} />
        <Route loader={({ params }) => screenEditLoader(params.partId!, params.lectureId!, +params.num!, params.screenId!, false)} path="lectures/:lectureId/:num/play/:screenId/edit" element={<AudioEditor />} />
        <Route loader={({ params }) => lecturePlayLoader(params.partId!, params.lectureId!, +params.num!, false)} path="lectures/:lectureId/:num/play/:screenId/editRecording" element={<AudioEditor />} />

        <Route path="lecturesdiff" loader={({ params }) => coursePartService.getDiffLectures(params.partId!)} element={<LecturesDiff />} />
        <Route loader={({ params }) => lectureLoader(params.partId!, params.lectureId!, true)} path="lecturesdiff/:lectureId" element={<ViewLectureDiff />} />
        <Route loader={({ params }) => lecturePlayLoader(params.partId!, params.lectureId!, +params.num!, true)} path="lecturesdiff/:lectureId/:num/:playtype/" element={<LecturePlayer />} />

    </>;

const partsRouter = <>
    <Route index path="" element={<PartInfo />} />
    <Route index path="texts" element={<PartInfo />} />
    {lecturesRouter}


    <Route path="links" element={<Links />} />
    <Route path="linksDiff" element={<LinksDiffMain />} loader={({ params }) => coursePartService.getDiffLinks(params.partId!)} />

    <Route path="files" element={<Files />} />
    <Route path="filesDiff" element={<FilesDiffMain />} loader={({ params }) => coursePartService.getDiffFiles(params.partId!)} />
    
    <Route path="tests" element={<Tests />} >
        <Route loader={({ params }) => testLoader(params.partId!, params.testId!)} path=":testId/:type" element={<EditTest />} />
    </Route>
    <Route path="testsDiff" element={<TestDiffMain />} loader={({ params }) => coursePartService.getDiffTests(params.partId!)} />

    <Route path="evaluations" element={<Evaluations />} />
    <Route path="general" element={<CoursePartGeneral />} />
    <Route path="print" element={<PrintPart />} />
    <Route path="publish" element={<Publish />} />
</>;


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />} errorElement={<ErrorBoundary />}>

            <Route index element={<Home />} />
            <Route path="switchuser" element={<SwitchUser />} />

            <Route  loader={({ params }) => coursePartService.loadPart({ partId: params.partId! })} path="coursepart/:partId" element={<CoursePartStart />} >
                {partsRouter}
            </Route>

            <Route loader={({ params }) => courseService.loadCourse(params.courseId!)} path="course/:courseId/" element={<CourseComponent />} >
                <Route index path="general" element={<CourseGeneral />} />
                <Route path="publish" element={<CoursePublish />} />
                <Route path="print" element={<PrintCourse />} />
                <Route path="addons" element={<CourseAddons />} />
                <Route path="content/:themeId" loader={() => coursePartCollectionService.getCourseParts(true)} action={() => coursePartService.purgePartOpenFromCourse()}  element={<EditTheme />} />
            </Route>

            <Route path="course/:courseId/content/:themeId/:partId" loader={({ params }) => courseLoader(params.courseId!, () => coursePartService.loadPart({ partId: params.partId!, openFromCourse: true }))}  element={<CoursePartStart />} >
            {partsRouter}
            </Route>


            <Route loader={() => coursePartCollectionService.getCourseParts(true)} path="/allparts" element={<AllCourseparts />} />
            <Route loader={() => courseCollectionService.getCourses(true)} path="/allcourses" element={<AllCourses />} />

        </Route>


    )
);

router.subscribe((state, opts) => {

    if (state.navigation.state === "idle") {

        if (state.matches[0].params["courseId"]) {
            // set last url for the part, in order to get to the right tab when switching between parts
            const courseId = state.matches[0].params["courseId"];
            const url = state.location.pathname;
            courseService.setDataUrl(courseId, url);
            if (!state.matches[0].params["partId"]) {
                coursePartService.purgePartOpenFromCourse();
            }

        } else if (state.matches[0].params["partId"]) {

            //courseService.clearCurrentCourseId();
            // set last url for the part, in order to get to the right tab when switching between parts
            const partId = state.matches[0].params["partId"];
            const url = state.location.pathname;
            coursePartService.setDataUrl(partId, url);
        }
        else {
            courseService.clearCurrentCourseId();
        }

    }

})

const navigateTo = (url: string) => {
    if (router) {
        router.navigate(url);
    }
}

const _navigate = router.navigate.bind(router);

type Listener = () => boolean | Promise<boolean>;
const listeners: Listener[] = [];

router.navigate = async (...args) => {
  const params = args as [any];

  if (listeners.length > 0) {
    const promises = listeners.map((fn) => fn());
    const values = await Promise.all(promises);
    const allowed = values.every(Boolean);

    if (!allowed) return;
  }

  return _navigate(...params);
};

courseService.setNavigator(navigateTo);


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <DevTools />
        <RouterProvider router={router} />
    </React.StrictMode>,
)







