import { useI18n } from "src/utils/lni18n";
import { FormatTimeDuration } from "src/utils/DateTimeFormat";
import { LecturePrintDTO } from "./domain";
import DOMPurify from 'dompurify';

const PrintPartLectures = (props: { lectures: LecturePrintDTO[] }) => {

  const { lectures } = props;
  const { languageService: t } = useI18n();

  if (!lectures || lectures.length === 0) return null;

  return (

    <>
      <h3 className="mt-5 mb-3 border-bottom">{t.getText("lectures")}</h3>
      {lectures!.map(l => <div key={l.ID} className="mt-3">
        <h4 >{l.Name}</h4>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( l.Description) }}></div>
        <div >
          {FormatTimeDuration(l.Duration, t)}.&nbsp;&nbsp;{t.getText("screens")} {l.NumOfScreens}
          {l.Screens && l.Screens.length > 0 && <table className="mt-3 table table-condensed table-borderless table-responsive">
            <tbody>

              {l.Screens.map((s, ii) => <tr >
                <td className="pr-3">{(ii + 1)})</td>
                <td className="pr-3 white-space-nowrap text-right">{s.Duration ? FormatTimeDuration(s.Duration, t) : t.getText("no.audio")}</td>
                <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( s.Transcript) }}></td>
              </tr>)}
            </tbody>
          </table>}

        </div>
      </div>)
      }
    </>




  )
}

export default PrintPartLectures;
