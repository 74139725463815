import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HtmlTextEditor from '../components/TextEditor/HtmlTextEditor';
import { useI18n } from '../utils/lni18n';
import coursePartService, { onePartatom } from './CoursePartService';
import { TextEditTypes } from './domain/SavePartTextDto';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import ReadOnlyPartInfo from './ReadOnly/ReadOnlyPartInfo';
import { TextDiffResponse } from './domain/TextDiffResponse';
import DOMPurify from 'dompurify';

const PartInfo = () => {

    const { partId } = useParams();

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const currentPart = partData.Data!;

    const [frames, setFrames] = useState(true);
    const [init, setInit] = useState(true);

    const [diffs, setDiffs] = useState<TextDiffResponse | undefined>(undefined);

  
    const [startData, setStartData] = useState({
        Intro: currentPart.Intro || "",
        Goals: currentPart.Goals || "",
        Literature: currentPart.Literature || ""
    });


    const showDiff = useCallback( async () => {
        if(diffs){
            setDiffs(undefined);    
        }else{
            const diff = await coursePartService.getTextDiff(partId!);
            setDiffs(diff);
        }
        
    },[diffs, partId] );

    const { languageService: t } = useI18n();

    const setValue = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        if (partId) {
            let type: TextEditTypes;
            if (Object.values(TextEditTypes).some(ty => ty === name)) {
                type = (name as unknown) as TextEditTypes;
            }
            else {
                throw new Error("Not a TextEditType");
            }

            coursePartService.saveText(partId, type, value);
            

        }
    }, [partId, diffs]);

    useEffect(() => {
        setStartData({
            Intro: currentPart.Intro || "",
            Goals: currentPart.Goals || "",
            Literature: currentPart.Literature || ""
        });

        setInit(true);
        const timerId = window.setTimeout(() => setInit(false), 100);
        setDiffs(undefined);

        return () => window.clearTimeout(timerId);
    }, [partId])

    if (currentPart && currentPart.Locked) return <ReadOnlyPartInfo />

    return currentPart ?

        <div className={`${diffs ? "" : "max-col-width"} ${frames ? "" : "hideFrames"}`}>
            <div className='my-4 d-flex'>
                <label className='pointer'>
                    <input checked={frames} onChange={(e) => setFrames(e.currentTarget.checked)} type="checkbox" />{t.getText("border")}
                </label>
                <button type="button" onClick={()=>showDiff()} className='ml-auto btn btn-inverse btn-small' >{ diffs ? t.getText("close"): "" } {t.getText("differences")}</button>

            </div>



            {!init && <>
                <div className='row mb-4'>
                    <div className='col-sm' >
                        <article>
                            <h3>{t.getText("introtext")}</h3>
                            <HtmlTextEditor onChange={setValue} initialValue={startData.Intro!} name="Intro"  ></HtmlTextEditor>
                        </article>
                    </div>
                    {diffs &&
                        <div className='col-sm' >
                            <h3>{t.getText("introtext")} {t.getText("differences")}</h3>
                            <div className='mce-content-body' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( diffs.Intro || "<p>&nbsp;</p>") }} />
                        </div>
                    }
                </div>

                <div className='row mb-4'>
                    <div className='col-sm' >
                        <article >
                            <h3>{t.getText("goal_contents")}</h3>
                            <HtmlTextEditor onChange={setValue} initialValue={startData.Goals} name="Goals"  ></HtmlTextEditor>
                        </article>
                    </div>
                    {diffs &&
                        <div className='col-sm' >
                            <h3>{t.getText("goal_contents")} {t.getText("differences")}</h3>
                            <div className='mce-content-body' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( diffs.GoalContents || "<p>&nbsp;</p>") }} />
                        </div>
                    }
                </div>

                <div className='row mb-4'>
                    <div className='col-sm' >

                        <article>
                            <h3>{t.getText("litterature")}</h3>
                            <HtmlTextEditor onChange={setValue} initialValue={startData.Literature} name="Litterature"  ></HtmlTextEditor>
                        </article>
                    </div>
                    {diffs &&
                        <div className='col-sm' >
                            <h3>{t.getText("litterature")} {t.getText("differences")}</h3>
                            <div className='mce-content-body' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( diffs.Litterature || "<p>&nbsp;</p>") }} />
                        </div>
                    }
                </div>


            </>}

            {/* dummy showing texts until ready for edit (init === false) */}
            {init && <>
                <article className="mb-4">
                    <h2>{t.getText("introtext")}</h2>
                    <div className='mce-content-body' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( startData.Intro) }}></div>
                </article>
                <article className="mb-4">
                    <h2>{t.getText("goal_contents")}</h2>
                    <div className='mce-content-body' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( startData.Goals) }}></div>
                </article>
                <article className="mb-4">
                    <h2>{t.getText("litterature")}</h2>
                    <div className='mce-content-body' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( startData.Literature) }}></div>
                </article>
            </>}

        </div>
        : null
}
export default PartInfo;