import { LnCollapseGroup } from "src/components/LnCard/LnCollapseGroup";
import { FilesGroupModelDiff } from "src/coursepart/domain/FilesGroupModel";
import FileCardDiff from "./FileCardDiff";
import LnIcon from "src/components/LnIcon";
import GetStateClass from "src/utils/StateColors";
import DOMPurify from 'dompurify';

export default function FileGroupDiff(props: {
    group: FilesGroupModelDiff, partId: string
}) {

    const { group, partId } = props;

    const name = <div className="d-flex align-items-center"> <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( group.Name) }}></div>{group.Files.length > 0 ? <LnIcon name="slides" className="icon-small ml-3" /> : <></>}</div>

    let bgstate = GetStateClass(group.State);

    return <LnCollapseGroup bgclass={bgstate} groupName={name} isOpen={false}   >
        <div>
            {group.Files.map((item) => (
                <FileCardDiff partId={partId} key={item.Id} file={item} />
            ))}
        </div>
    </LnCollapseGroup>;



}