
import { useI18n } from 'src/utils/lni18n';
import { EvalEditAnswerOptionDTO } from '../domain/EvaluationEditDataDTO';
import DOMPurify from 'dompurify';

export default function StandardAnswerOption(props: { haveScores: boolean, option: EvalEditAnswerOptionDTO }) {

    const { option, haveScores } = props;
    const { languageService: t } = useI18n();
    return (

        <div className='border-top pt-3 d-flex bg-white' key={option.Id}>
            <div className='flex-fill mb-3' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( option.AnswerText) }}  ></div>
            {haveScores && <div>{t.getText("score")}: {option.Score}</div>}
        </div>

    )
}