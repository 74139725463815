
import { useEffect, useState } from 'react';
import { useI18n } from '../utils/lni18n';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import WaitRipple from 'src/components/WaitRipple';
import classes from "./CoursePart.module.scss";
import LnIcon from 'src/components/LnIcon';
import courseService, { oneCourseatom } from './CourseService';
import LockedReason from 'src/components/LockedReason';
import { LockReason } from 'src/types';
import { CourseEditResponse } from './domain/CourseEditModel';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from 'src/components/Modalservice/ConfirmationDialog';
import { urlService } from 'src/services/NavService';
import { useCurrentUser } from 'src/services/user/userService';

export default function CourseComponent() {

    const dialogPortal = useDialog();
    const navigate = useNavigate();
    const { currentUser } = useCurrentUser();
    const { courseId } = useParams();
    const [course] = useAtom(
        useMemo(() => oneCourseatom(courseId || ""), [courseId]));

    const { languageService: t } = useI18n();
    const [prepPreview, setprepPreview] = useState(false);


    if (!course) {
        return <WaitRipple />
    }

    const unlock = () => {
        courseService.loadCourse(course.Course.Id, true);
    }

    let openAgainButton = null;
    if (course.Locked && course.Locked!.Reason === LockReason.LockedBySelf) {
        openAgainButton = <button className='mt-3 ml-3 btn btn-warning btn-small' onClick={unlock} >{t.getText("yes")}</button>;
    }

    function closeCourse(course: CourseEditResponse): void {

        if (course.Locked || !course?.IsDirty) {
            doCloseCourse(course.Course.Id);
            return;
        }

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("close.editor"),
                    message: <div className="preserve-white" >{t.getText("publish.before.close")}</div>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then(async (res: boolean) => {
            if (res) {
                navigate(urlService.urlToCourse(courseId!, "publish"));
            }
            else {
                doCloseCourse(course.Course.Id);
            }
        });


    }

    async function doCloseCourse(courseId: string) {
        const nextCourseId = await courseService.closeCourse(courseId);
        if (nextCourseId) {
            navigate(urlService.urlToCourse(nextCourseId));
        }
        else {
            navigate(`/`);
        }
    }

    async function previewCourse() {
        try {
            setprepPreview(true);
            const url = await courseService.previewCourse(course.Course.Id, currentUser!.Id);
            if (url !== null) {
                window.open(url, '_blank');
            }
        }
        finally {
            setprepPreview(false);
        };
    }

    return <>

        <div className={"scrollContent scroller "} aria-live="polite">
            <div className="card-box big-card-box">
                <div className="card-box-title card-title ">
                    <div className='w-100 d-flex align-items-start'>
                        <h3>
                            {course?.Course.Name}
                            {course?.Locked && <LnIcon className='ml-3' name="locked" />}
                        </h3>


                        <div className='ml-auto d-flex'>
                            <LockedReason info={course!.Locked!} >{openAgainButton}</LockedReason>
                            <div>
                                <button disabled={prepPreview} onClick={previewCourse} className="ml-3 btn btn-primary btn-small" type="button">
                                    {prepPreview ? t.getText("wait.moment") : t.getText("preview")}
                                </button>
                            </div>
                            <button onClick={(e) => closeCourse(course!)} className="ml-5 btn btn-link" type="button">
                                <LnIcon name="close" />
                            </button>

                        </div>
                    </div>
                </div>
                <div className="card-box-content card-body">

                    <main className="">
                        <Outlet />
                    </main>
                </div>
            </div>
        </div>
    </>



}