//@ts-check
import { useCallback, useEffect, useState, useMemo } from "react";
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    defaultDropAnimationSideEffects,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import type { Active, DragEndEvent, DragOverEvent, DropAnimation, UniqueIdentifier } from "@dnd-kit/core";
import {
    SortableContext,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import coursePartService, { onePartatom } from "../CoursePartService";
import { arrayMoveMutate } from "../../utils/ArrayMethods";
import { FileModel } from "../domain/FilesGroupModel";
import FileGroup from "./FileGroup";
import FileCard from "./FileCard";
import { useImmerAtom } from 'jotai-immer'
import { useParams } from "react-router-dom";
import { InputDialog, InputOptions } from "src/components/Modalservice/InputDialog";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import { useI18n } from "src/utils/lni18n";
import ReadOnlyFiles from "../ReadOnly/File/ReadOnlyFiles";
import { Link } from "react-router-dom";


const Files = () => {


    const { partId } = useParams();
    const dialogPortal = useDialog();
    const { languageService: t } = useI18n();

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData, setPartDataFunction] = useImmerAtom(partAtom);
    const part = partData.Data;

    const groupsId = "thegroups";

    const [active, setActive] = useState<Active | null>(null);

    const groups = part?.FileGroups || [];

    const [groupIds, setGroupIds] = useState<UniqueIdentifier[]>([]);

    const getFileById = useCallback((id: UniqueIdentifier) => {
        let file: FileModel | undefined;
        part?.FileGroups.forEach(lg => lg.Files.forEach(fi => {
            if (fi.Id === id) file = fi;
        }
        ));

        return file;
    }, [part]);

    const getGroupById = useCallback((id: UniqueIdentifier) => {
        return part!.FileGroups.find(g => g.Id === id)!;
    }, [part]);

    useEffect(() => {
        if (part?.FileGroups) {
            setGroupIds(part.FileGroups.map(lg => lg.Id as UniqueIdentifier));
        }
    }, [part?.FileGroups]);


    if (!part || !part.FileGroups) {
        return null;
    }


    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const dragOver = (e: DragOverEvent) => {
        const { active, over } = e;
        if (!over) {
            return;
        }
        if (active.data.current?.sortable.containerId === groupsId
            && active.data.current?.sortable.index !== undefined
            && over.data.current?.sortable.index !== undefined) {
            setPartDataFunction(partData => {
                if (partData.Data?.FileGroups) {
                    arrayMoveMutate(partData.Data.FileGroups, active.data.current!.sortable.index, over.data.current!.sortable.index);
                }
            });

            return;
        }

        if (active.data.current?.sortable.containerId !== over!.data.current?.sortable.containerId) {

            setPartDataFunction(partData => {

                const gs = partData.Data!.FileGroups;

                const newGroup = gs.find(g => g.Id === over?.data.current?.sortable.containerId);
                const oldGroup = gs.find(g => g.Id === active.data.current?.sortable.containerId);
                const file = oldGroup?.Files.find(l => l.Id === active.id);

                if (file && oldGroup && newGroup) {
                    oldGroup.Files = oldGroup.Files.filter(l => l.Id !== active.id);
                    newGroup.Files.splice(over?.data.current?.sortable.index, 0, file);
                }

            });

        }
        else {  // same container
            setPartDataFunction(partData => {

                const groupsToChange = partData.Data!.FileGroups;
                const group = groupsToChange.find(g => g.Id === active.data.current?.sortable.containerId);
                if (group) {
                    arrayMoveMutate(group.Files, active.data.current?.sortable.index, over?.data.current?.sortable.index);
                }
            });
        }

    }

    const dragEnd = async (ev: DragEndEvent) => {
        setActive(null);
        await coursePartService.saveFileGroups(part.Id)
    };


    const dropAnimation: DropAnimation = {
        sideEffects: defaultDropAnimationSideEffects({
            styles: {
                active: {
                    opacity: '0.5',
                },
            },
        }),
    };

    const newGroup = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: InputOptions = {
                    title: t.getText("add.link.group"),
                    message: <div>{t.getText("name")}</div>,
                    languageService: t,
                    onCancel: onCancel,
                    onSubmit: onSubmit,
                    type: "input",
                }
                return <InputDialog {...dprops} />
            },
            size: "md"
        }).then((name: string) => {
            if (name !== "") {
                coursePartService.addFileGroup({ name, partId: partId! })
            }
        });
    }

    if (!part) return null;
    if (part?.Locked) return <ReadOnlyFiles />

    return (
        <div className="max-col-width">
            <DndContext
                sensors={sensors}

                onDragStart={({ active }) => {
                    setActive(active);
                }}
                onDragOver={dragOver}
                onDragEnd={dragEnd}
                onDragCancel={() => {
                    setActive(null);
                }}
            >
                <div className="d-flex mb-3">
                    <Link to={"../filesDiff"} className='ml-auto btn btn-inverse btn-small' >{t.getText("differences")}</Link>
                </div>

                <div role="list">
                    <SortableContext items={groups.map(l => l.Id)} id={groupsId}>
                        {groups.map(item => <FileGroup partId={partId!} active={active?.id} closed={!item.IsDefault} key={item.Id} group={item} />)}
                    </SortableContext>
                </div >

                {createPortal(
                    <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>

                        {active
                            ? groupIds?.includes(active.id)
                                ? <FileGroup partId={partId!} dragged={true} closed={true} key={active.id} group={getGroupById(active.id)} />
                                : <FileCard deleteFile={() => { }} partId={partId!} edit={(e) => { }} dragged={true} file={getFileById(active.id)!} />
                            : null}
                    </DragOverlay>,
                    document.body
                )}

                <div className="mb-4 text-right">
                    <button onClick={newGroup} className="btn btn-small btn-primary">{t.getText("add.more.group")}</button>
                </div>

            </DndContext>
        </div>
    )
}

export default Files;
