import {useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { onePartatom } from "src/coursepart/CoursePartService";
import ReadOnlyFileGroup from "./ReadOnlyFileGroup";


const ReadOnlyFiles = () => {


  const { partId } = useParams();

  const partAtom = useMemo(() => onePartatom(partId!), [partId]);
  const [partData] = useAtom(partAtom);
  const part = partData.Data;

  const groups = part?.FileGroups || [];

  if (!part || !part.FileGroups) {
    return null;
  }

  return (
    <div className="max-col-width">

        <div role="list">
            {groups.map(item => <ReadOnlyFileGroup partId={partId!} key={item.Id} group={item} />)}
        </div >

     

    </div>
  )
}

export default ReadOnlyFiles;
