import { useI18n } from '../../utils/lni18n';
import { useCallback, useState, useMemo, useEffect } from "react";
import classes from './lecture.module.scss';
import { Active, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, UniqueIdentifier, useSensors, useSensor, DragOverlay, DropAnimation, defaultDropAnimationSideEffects, DragOverEvent } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import lectureService, { oneLectureatom } from './LectureService';

import {
    Menu,
    Item,
    useContextMenu,
    ItemParams,
    contextMenu,
    PredicateParams,

} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import coursePartService, { onePartatom } from '../CoursePartService';
import { arrayMoveMutate } from 'src/utils/ArrayMethods';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from '../../components/Modalservice/ConfirmationDialog';
import ScreenLink from './ScreenLink/ScreenLink';
import { useDropzone } from 'react-dropzone';
import { useCurrentUser } from 'src/services/user/userService';
import fileUploadservice from 'src/components/FileUpload/FileUploadService';
import { FileOptions, FileUploadProps } from '../domain/FileDialogsettings';
import screenService from './Screen/ScreenService';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { produce } from 'immer';
import { useAtom } from 'jotai';
import { EditLectureDto, EditScreenDto, LectureUpdatTextsCommand } from './domain/LectureState';
import LnIcon from 'src/components/LnIcon';
import CreateLectureForm from './CreateLectureForm';
import FileUploadDialog from 'src/components/FileUpload/FileUploadDialog';
import scrapBookService from 'src/components/ScrapBook/ScrapBookService';
import ScrapBookDialog, { ScrapBookDialogResult, ScrapType } from 'src/components/ScrapBook/ScrapBookDialog';
import { CopyScreenCommand } from './domain/CopyScreenCommand';
import { usePartPath } from 'src/utils/usePartPath';
import ViewLecture from '../ReadOnly/Lecture/ViewLecture';
import OriginalFilesDialog from './OriginalFiles/OriginalFilesDialog';


const MENU_ID = "lect-menu-id";
const screenMimeType = "application/screen-json";

// export type LetureEvent<T> = { value: T };

const fileOptions: FileOptions = {
    maxSize: "10gb",
    accepts: {
        "image/*": [".jpg", ".jpeg", ".png", ".gif", ".tif", ".tiff", ".bmp"],
        "application/pdf": [".pdf"],
        "application/vnd.ms-powerpoint": [".pptx"],
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
        "application/vnd.oasis.opendocument.presentation": [".pptx"],
        "application/x-vnd.oasis.opendocument.presentation": [".pptx"],
        "video/*": [".wmv", ".mov", ".mp4", ".mpeg", ".avi", ".flv"],
    }
};

const EditLecture = () => {

    const { partId, lectureId } = useParams();
    const [isVisibleMenu, setVisibilityMenu] = useState(false);
    const [downLoadPublished, setDownLoadPublished] = useState(false);

    const navigate = useNavigate();
    const partPath = usePartPath();

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();




    const { currentUser } = useCurrentUser();

    const [lectureData, setLectureDataFunc] = useAtom(useMemo(() => oneLectureatom(lectureId!), [lectureId]));
    const screens = lectureData?.Screens || [];

    const [multipleSelected, oneSelected] = useMemo(() => {
        const num = screens && screens.filter(s => s.Selected).length;
        return [num > 1, num > 0]
    }, [screens]);



    const [partData] = useAtom(
        useMemo(() => {
            return onePartatom(partId!);
        }
            , [lectureData]));



    const onDropNewFile = useCallback((files: File[]) => {
        if (files && files.length > 0) {
            doUpload(files[0]);
        }
    }, [])


    const [active, setActive] = useState<Active | null>(null);

    const { show, hideAll } = useContextMenu({
        id: MENU_ID
    });


    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop: onDropNewFile,
        accept: fileOptions.accepts,
        noClick: true,
        multiple: false
    });

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const getScreenById = useCallback((id: UniqueIdentifier) => {
        return lectureData?.Screens.find(t => t.ScreenId === id);
    }, [lectureData]);



    const dragOver = useCallback((ev: DragOverEvent) => {
        const { active, over } = ev;
        if (!over) {
            return;
        }
        if (over && active.id !== over?.id) {
            if (lectureData) {

                const newData = produce(lectureData, lecture => {
                    arrayMoveMutate(lecture!.Screens, active.data.current?.sortable.index, over.data.current?.sortable.index);
                    lecture!.Screens.forEach((screen, i) => screen.Order = i);
                });

                setLectureDataFunc(newData);
            }
        }
    }, [screens]);

    const dragEnd = useCallback(async (ev: DragEndEvent) => {
        setActive(null);
        await lectureService.saveScreensOrder(lectureData!.LectureId);

    }, [screens, setActive]);


    const doUpload = (fi: any) => {

        fileUploadservice.startUploadState();
        const message = (<>
            <div>{t.getText("screens")}</div>
            <div>{lectureData?.Name}</div>
        </>);

        const response = fileUploadservice.uploadFile(message, currentUser!.Id, fi);
        lectureService.addScreen(currentUser!.Id, lectureData!, response.promise);

    }

    const dropAnimation: DropAnimation = {
        sideEffects: defaultDropAnimationSideEffects({
            styles: {
                active: {
                    opacity: '0.5',
                },
            },
        }),
    };

    function gotNoSound({ triggerEvent, props, data }: PredicateParams<EditScreenDto, any>) {
        return multipleSelected || !props?.GotSound;
    }
    function gotNoVideo({ triggerEvent, props, data }: PredicateParams<EditScreenDto, any>) {
        return multipleSelected || !props?.VideoUrl;
    }

    const filterForNoscreenSelected = ({ triggerEvent, props, data }: PredicateParams<EditScreenDto, any>) => {
        return !oneSelected;
    }


    function displayContextMenu(e: React.MouseEvent, screen: EditScreenDto) {

        if (!screen.Selected) {
            screenService.SelectScreen(screen, false, false);
        }

        show({
            event: e,
            props: screen
        });
    }

    async function handleItemClick(params: ItemParams<EditScreenDto, any>) {

        const { id, props }: ItemParams<EditScreenDto, any> = params;

        switch (id) {

            case "open":
                playLecture(props!.Order);
                break;

            case "delete":
                deleteScreen(props!);
                break;

            case "copy":
                if (!!props) {
                    await scrapBookService.addScreen(props!, partId!);
                }
                break;

            case "deletesound":
                removeSound(props!);
                break;

            case "getvideoorignal":
                getVideoOriginalFile(props!.ScreenId);
                break;
        }
    }

    function showContextMeny(e: React.MouseEvent) {

        if (isVisibleMenu) {
            hideAll();
            return;
        }

        const selectedScreens = screens?.filter(s => s.Selected);
        // if (!selectedScreens || selectedScreens.length === 0) {
        //     return;
        // }

        const elem = e.currentTarget;
        var rect = elem.getBoundingClientRect();

        contextMenu.show({
            id: MENU_ID,
            event: e,
            props: selectedScreens[0],
            position: { x: rect.x, y: rect.y + rect.height + 3 }
        });
    }


    function deleteScreen(screen: EditScreenDto) {
        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("screen") + " " + (screen.Order + 1),
                    message: <><div>{t.getText("delete_general_confirm")}</div></>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        });

        p.then((res) => {
            if (res) {
                lectureService.deleteScreen(screen.LectureId, screen.ScreenId);
            }
        })
            .catch(() => { });

    }

    function removeSound(screen: EditScreenDto) {
        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("screen") + " " + (screen.Order + 1),
                    message: <><div className='preserve-white'>{t.getText("sound.delete.warning")}</div></>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            }
        });

        p.then((res) => {
            if (res) {
                screenService.RemoveSound(screen);
            }
        }).catch(() => { });

    }

    function openFileDialog() {

        const promise = dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: FileUploadProps = {
                    fileOptions: fileOptions,
                    languageService: t,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    backGroundMessage: (<>
                        <div>{t.getText("screens")}</div>
                        <div>{lectureData?.Name}</div>
                    </>)
                };
                return <FileUploadDialog {...dprops} />
            }
        });

        lectureService.addScreen(currentUser!.Id, lectureData!, promise);
    }


    const playLecture = (num: number) => {
        navigate(`${num}/play`);
    }

    const editLectureData = (data: EditLectureDto) => {

        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <CreateLectureForm header={t.getText("edit")} oldValues={{ name: data.Name, description: data.Description }} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });

        p.then((lectData: any) => {
            const cmd: LectureUpdatTextsCommand = {
                LectureId: data.LectureId,
                Name: lectData.name,
                Description: lectData.description
            }

            lectureService.updateTexts(cmd);
            coursePartService.updateLectureText(partId!, cmd)
        }).catch(() => { });
    }

    const showScrapBook = () => {

        scrapBookService.loadScreens();
        const promise = dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <ScrapBookDialog contextId={lectureId!} type={ScrapType.screen} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });

        promise.then((data: ScrapBookDialogResult) => {

            if (data.file) {
                doUpload(data.file);
            }
            else {
                const cmd: CopyScreenCommand = {
                    screen: data.id,
                    destCoursepart: partId!,
                    destLecture: lectureId!
                }
                lectureService.copyScreen(cmd)
                    .then(() => scrapBookService.removeScreen(data.id));
            }
        });


    }

    const handleOriginals = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <OriginalFilesDialog partId={partId!} lectureId={lectureId!} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });
    };

    const getAllOriginalFiles = useCallback(() => {
        lectureService.getZippedLecture(lectureId!, downLoadPublished);
    }, [downLoadPublished]);

    const getVideoOriginalFile = (screenId: string) => {
        lectureService.getVideoOriginal(lectureId!, screenId);
    };



    if (partData.Data && partData.Data.Locked) return <ViewLecture />


    return <div className={"scrollContent scroller "} aria-live="polite">
        <div {...getRootProps()} className={"card-box big-card-box " + (isDragActive ? "bg-primary-lightest" : "")}>
            <input {...getInputProps()} />
            <div className="card-box-title card-title d-flex">
                <div>
                    <h3>
                        {lectureData?.Name}
                        <button className="btn btn-link mx-3" type="button" onClick={() => editLectureData(lectureData!)}>
                            <LnIcon name="edit" />
                        </button>
                    </h3>
                    <div className='preserve-white'>{lectureData?.Description}</div>
                </div>
                <Link className="btn btn-primary btn-sm" to={`./../`} >{t.getText("back")}</Link>
            </div>
            <div className="card-box-content card-body">
                <div className="d-flex flex-wrap">

                    <button type="button" onClick={() => playLecture(0)} className='mr-3 mb-3 btn btn-inverse btn-small'>{t.getText("display")}</button>
                    <button type="button" onClick={openFileDialog} className='mr-3 mb-3 btn btn-primary btn-small'>{t.getText("add")}</button>
                    <button type="button" onClick={showScrapBook} className='mr-3 mb-3 btn btn-primary btn-small'>{t.getText("scrapbook")}</button>
                    <button type="button" onClick={handleOriginals} className='mr-3 mb-3 btn btn-primary btn-small'>{t.getText("lecture.edit.files")}</button>
                    
                    <div>
                        <button type="button" onClick={getAllOriginalFiles} className='mr-3 mb-3  btn btn-primary btn-small'>{t.getText("lecture.download.zip")}</button>
                        <br />
                        <label className='mt-1 ml-3'>
                            <input type="checkbox" checked={downLoadPublished} onChange={(e) => setDownLoadPublished(e.currentTarget.checked)} />
                            {t.getText("course.mode.publish")}
                        </label>
                    </div>

              
                    <Link to={`./../../lecturesdiff/${lectureId}`} className='mr-3 mb-3 btn btn-inverse btn-small' >{t.getText("differences")}</Link>


                </div>
                <DndContext
                    sensors={sensors}
                    onDragStart={({ active }) => {
                        setActive(active);
                    }}
                    onDragOver={dragOver}
                    onDragEnd={dragEnd}
                    onDragCancel={() => {
                        setActive(null);
                    }}
                >

                    <div className={"mt-5 flex-row flex-wrap " + classes.cardWrapper}>

                        {(!screens || screens.length === 0) && <h4>{t.getText("empty.lecture.instructions")}</h4>}

                        <SortableContext items={screens?.map(l => l.ScreenId as UniqueIdentifier)}>
                            {screens?.map(screen => <ScreenLink playLecture={playLecture} onContextMenu={e => displayContextMenu(e, screen)} active={active?.id} screen={screen} key={screen.ScreenId} />)}
                        </SortableContext>
                    </div>

                    {createPortal(
                        <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>

                            {active
                                ? <ScreenLink onContextMenu={a => { }} dragged={true} screen={getScreenById(active.id)!} />
                                : null}
                        </DragOverlay>,
                        document.body
                    )}


                </DndContext>


                <Menu id={MENU_ID} onVisibilityChange={(v: boolean) => setVisibilityMenu(v)}>

                    <Item id="open" hidden={filterForNoscreenSelected} onClick={handleItemClick} >
                        {t.getText("open")}
                    </Item>

                    <Item id="copy" hidden={filterForNoscreenSelected} onClick={handleItemClick} >
                        {t.getText("copy")}
                    </Item>

                  

                    <Item hidden={gotNoSound} id="deletesound" onClick={handleItemClick}>
                        {t.getText("sound.remove")}
                    </Item>


                    <Item hidden={gotNoVideo} id="getvideoorignal" onClick={handleItemClick}>
                        {t.getText("download.orginal.video")}
                    </Item>


                    <Item id="delete" hidden={filterForNoscreenSelected} onClick={handleItemClick}>
                        {t.getText("delete")}
                    </Item>




                </Menu>


            </div>
        </div>
    </div>

}

export default EditLecture;
