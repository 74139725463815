
import { useI18n } from 'src/utils/lni18n';
import classes from "./eval.module.scss";
import { EvalQuestionType, EvaluationQuestionDTO } from '../domain/EvaluationEditDataDTO';
import { useState } from 'react';
import StandardAnswerOption from './StandardAnswerOption';
import DOMPurify from 'dompurify';

export default function StandardEvalQuestion(props: { q: EvaluationQuestionDTO, num: number }) {

    const { languageService: t } = useI18n();
    const { q, num } = props;
    const [edit, setEdit] = useState(false);

    const changeEditState = () => {
        setEdit(e => !e);
    };

    return <div className={classes.question + " max-col-width"} key={q.Id} >
        <div className='alert alert-grey d-flex'>
            <div className='pointer' onClick={() => changeEditState()}>
                <strong>{t.getText("question")}&nbsp;{(num + 1)}</strong>
            </div>

            <div className="flex-fill ml-3 pointer" onClick={() => changeEditState()} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( q.QuestionText) }}></div>

            <div className='d-flex align-items-center'>
                <button className='p-2 pr-3 btn btn-link' onClick={() => changeEditState()}>
                    <span className={`${edit ? "dark-caret-point-left" : "dark-caret"}`}></span>
                </button>
            </div>

        </div>
        {edit &&
            <div className="ml-3">
                {q.Type === EvalQuestionType.MultipleChoice &&

                    q.AnswerOptions.map(o =>
                        <StandardAnswerOption 
                            haveScores={true}
                            key={o.Id} option={o} />
                    )

                }

                {q.Type === EvalQuestionType.FreeText && <div >{t.getText("openquestion")}</div>}
            </div>
        }

    </div>



}