import React, { ReactElement, useState } from 'react';

import {
    Card, CardBody, CardHeader, Collapse
} from 'reactstrap';

import LnIcon from "../LnIcon";

import classes from './lncard.module.scss';

const LnCollapseGroup = (props: { bgclass?: string, groupName: string | ReactElement, isOpen: boolean, children: any, handle?: ReactElement, className?: string, actionButton?: ReactElement  }) => {

    const { groupName, handle, className, bgclass } = props

    const [isOpen, setIsOpen] = useState(props.isOpen || false);

    const toggle = () => setIsOpen(!isOpen);



    return (
        <div className={'card ' + className}>
            <div className={`${classes.cardLeftIndicator} ${bgclass}`}></div>
            <CardHeader className={classes['ln-group-card-header'] + " bg-light "  + (isOpen ? "" : classes.closed)} >
                <div className='d-flex '>
                    <div className='flex-fill'>
                        <button onClick={toggle} type="button" className="noFocus w-100 text-left btn btn-link">{groupName}</button>
                    </div>
                    <div className='d-flex align-items-center'>
                        {props.actionButton}
                        {handle}
                        <button onClick={toggle} type="button" className="ml-3 btn btn-link">
                            {isOpen ? <LnIcon className={[classes['toggle-icon'], 'natural'].join(' ')} name="minus" /> : <LnIcon className={[classes['toggle-icon'], 'natural'].join(' ')} name="plus" />}
                        </button>

                    </div>

                </div>

            </CardHeader>

            <Collapse isOpen={isOpen}>
                <CardBody>
                    {props.children}
                </CardBody>
            </Collapse>

        </div>


    )

}

export { LnCollapseGroup };
