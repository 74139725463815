import { useMemo } from "react";
import { onePartatom } from "../../CoursePartService";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import FileGroupDiff from "./FileGroupDiff";
import { FilesGroupModelDiff } from "src/coursepart/domain/FilesGroupModel";

const FilesDiff = ( props:{ fileGroups: FilesGroupModelDiff[] } ) => {
    const { partId } = useParams();
    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const {fileGroups} = props;

    if (!partData || !fileGroups) {
        return null;
    }
    return (
        <div className="max-col-width">
            <div role="list">
                {fileGroups.map(item => <div role="listitem" key={item.Id} className="mb-4" ><FileGroupDiff partId={partId!} group={item} /></div>)}
            </div >
        </div>
    )
}

export default FilesDiff;
