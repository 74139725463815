import { useAtom } from 'jotai';
import { MCDataQuestion } from '../DomainModels';
import { useI18n } from "src/utils/lni18n";
import { MCTestService } from './MCTestService';
import classes from "./McTest.module.scss";
import LnIcon from 'src/components/LnIcon';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from 'src/components/Modalservice/ConfirmationDialog';
import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';

const QuestionsList = (props: { service: MCTestService, data: MCDataQuestion[], onDelete: (index: number) => void, onEdit: (index: number) => void }) => {
  const { languageService: t } = useI18n();
  const { data, onEdit, onDelete, service } = props;
  const dialogPortal = useDialog();
  const { state } = useLocation();

  const [initData] = useAtom(service.currentQuestionInitialAtom);


  if (!data) return null;

  const addQuestion = () => {
    service.addQuestion();
  }
  const deleteQuestion = (i: number) => {
    dialogPortal({
      factory: (onSubmit, onCancel) => {
        const dprops: ConfirmationOptions = {
          className: "",
          title: t.getText("delete"),
          message: <div className="preserve-white" >{t.getText("really.sure")}</div>,
          languageService: t,
          show: true,
          onClose: onCancel,
          onSubmit: onSubmit,
          buttons: ConfirmationButtons.YesNo
        }
        return <ConfirmationDialog {...dprops} />
      },
      size: "md"
    }).then((res: boolean) => {
      if (res) {
        onDelete(i);
      }
    });

    
  }


  return <div className="border p-3 mb-3">
    <div className='font-weight-bold mb-3'>{t.getText("questions")}</div>
    
    <button disabled={state?.readOnly} onClick={addQuestion} type='button' className='btn btn-primary btn-small mb-3' >{t.getText("add")}</button>
    
    {data.map((d, i) => <div key={i} className={`d-flex px-3 ${initData?.index === i ? "bg-primary-lightest" : ''}`}>
      <button type='button' className='d-flex flex-fill btn btn-link noFocus' onClick={() => onEdit(i)}>
        <div className={`${classes.testListText}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( d.Question) }} ></div>
      </button>

      {(data.length > 2) && <button disabled={state?.readOnly} className="btn btn-link ml-3" type="button" onClick={() => deleteQuestion(i)}>
        <LnIcon name="delete" className='icon-small' />
      </button>}

    </div>)}
    
  </div>

}

export default QuestionsList;

