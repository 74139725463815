import classes from './files.module.scss';
import { useI18n } from '../../utils/lni18n';
import { FileModel } from "../domain/FilesGroupModel";
import { DragHandle } from "../../components/DragHandle/DragHandle";
import FileIcon from "../../components/FileIcons/FileIcon";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';
import { API_URL } from 'src/utils/constants';
import LnIcon from 'src/components/LnIcon';
import DOMPurify from 'dompurify';


const FileCard = (props: { edit: (file: FileModel) => void, partId: string, file: FileModel, dragged?: boolean, dummy?: boolean, active?: UniqueIdentifier, deleteFile: ((id: string) => void) }) => {

    const { languageService: t } = useI18n();
    const { file, dragged, dummy, active, partId, deleteFile, edit } = props;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: file?.Id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""
    };



    if (!file) {
        return null;
    }

    const deleteThisFile = () => {
        deleteFile(file.Id)
    }

    const editFile = () => {
        edit(file);
    }

    const url = `${API_URL}/author/coursepart/${partId}/file/${file.Id}/download?name=${encodeURIComponent(file.FileName)}`

    return <>
        {!dummy &&
            <div ref={setNodeRef} id={"c_" + file.Id} key={file.Id}
                className={"row align-items-center py-2 mx-4 border-top " + (active === file.Id ? " " + classes.active : "")} style={style}>


                <div className="col-1">

                    <FileIcon className={["icon-small",classes['file-icon']].join(' ')} name={(file.FileName || '').split('.').pop()} />

                </div>
                

                <div className={"col-4 " + classes.lineClamp} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( file.Description) }}>
                </div>

                <div className='col-1'>
                    <button className="btn btn-link" type="button" onClick={editFile}>
                        <LnIcon name="edit" className='icon-small' />
                    </button>
                </div>

                <div className="col-3">
                <div className={classes.fileInfo}>{file.FileName}</div>
                    <span >{t.getMediumDateString(file.LastModified)}</span>
                </div>

                <div className='col-1'>
                    <a target="_blank" rel="noopener noreferrer nofollow" title={t.getText('download')} className="" href={url}>
                        <LnIcon name='download2' className='icon-small' />
                    </a>

                </div>
                <div className='col-1'>
                    <button className='btn btn-link' type='button' onClick={deleteThisFile}>
                        <LnIcon name='delete' className='icon-small' />
                    </button>
                </div>
               

                <div className={"col-1" + classes.dragHandle} {...attributes} {...listeners}><DragHandle /></div>

            </div>
        }

        {dummy &&
            <div ref={setNodeRef} id={"c_" + file.Id} key={file.Id} className={classes.dummyCard} style={style} >
            </div>
        }
    </>
}

export default FileCard;
