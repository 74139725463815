import { useI18n } from "src/utils/lni18n";
import { TestPrintDTO } from "./domain";
import classes from "../Print.module.scss";
import DOMPurify from 'dompurify';

const PrintPartTests = (props: { tests: TestPrintDTO[] }) => {

    const { tests } = props;
    const { languageService: t } = useI18n();

    if (!tests || tests.length === 0) return null;

    return (
        <div className={`py-3 ${classes.tests}`} >
            <h3 className="mt-5 mb-3 border-bottom">{t.getText("tests")}</h3>
            {tests!.map(test => <div key={test.Id} className="py-3">
                <div className="no-page-break">
                    <h4 >{test.Name}</h4>
                    <p>{test.Type}</p>
                    <div className="mb-3 preserve-white" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( test.Description) }}></div>
                    {test.Instructions && <div className="mb-3">
                        <strong>{t.getText("extra.instructions.to.students")}</strong>
                        <div className="" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( test.Instructions) }}></div>
                    </div>}

                    {test.Meta && <div className="mb-3">
                        <strong>{t.getText("guidelines")}</strong>
                        <div className="" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( test.Meta) }}></div>
                    </div>}

                </div>
                {test.MultipleChoiceQuestions && <>
                <strong>{t.getText("questions")}</strong><div className="ml-4">
                    {test.MultipleChoiceQuestions.map((q, i) =>
                        <div className="mb-3 no-page-break" key={i}>
                            <div className="d-flex">
                                <span className="mr-3">{`${(i + 1)})`}</span>
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( q.Question) }}></div>
                            </div>

                            <div className="ml-3">
                                {q.Answers.map((a, i) => <div key={`a${i}`} className="d-flex align-items-start" >
                                    <span>{a.Correct ? "☑" : "☐"}</span>
                                    <div className="ml-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( a.Text) }} ></div>
                                </div>)}
                            </div>

                        </div>

                    )}
                </div>
                </>}

            </div>)
            }
        </div>

    )
}
export default PrintPartTests;