
import { useCallback, useEffect, useState } from 'react';
import { useI18n } from '../utils/lni18n';
import coursePartService, { onePartatom } from './CoursePartService';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Outlet, useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import WaitRipple from 'src/components/WaitRipple';
import screenEncodingService from './Lecture/Screen/ScreenEncodingService';
import classes from "./CoursePart.module.scss";
import LnIcon from 'src/components/LnIcon';
import CoursePartLoadError from './CoursePartLoadError';
import { urlService } from 'src/services/NavService';
import { usePartPath } from 'src/utils/usePartPath';

export default function CoursePartComponent() {

    const { partId, themeId, courseId } = useParams();
    let location = useLocation()
    const navigate = useNavigate();

    
    const [block, setBlock] = useState(true);

    useEffect(() => {
        if (partId) {
            screenEncodingService.clearScreens(partId);
        }
    }, [partId]);


    const [partData] = useAtom(
        useMemo(() => onePartatom(partId || ""), [partId]));


    const blocker = useBlocker(true);

    useEffect(() => {
        if (!block) {
            setBlock(true);
        }
    }, [block])

    // block navigate away if dirty and user want to publish...
    useEffect(() => {

        if (!blocker || blocker.state !== "blocked") return;

        if (!block || blocker.location.pathname.indexOf(partId!) > -1) {
            blocker.proceed();
            return;
        };

        coursePartService.checkIfPartCanClose(partId!).then(res => {
            if (res && blocker.reset) {
                blocker.reset();
                window.setTimeout(() => {
                    if (courseId && themeId) {
                        navigate(urlService.urlToPartInCourse(courseId, themeId, partId!, "publish"));
                    }
                    else {
                        navigate(urlService.urlToPart(partId!, "publish"));
                    }
                }, 100);
            }
            else {
                if (blocker.proceed) {
                    blocker.proceed();
                }

                coursePartService.closePart(partId!, true);
            }

        });
    }, [partId, blocker, block]);



    const { languageService: t } = useI18n();


    if (!partData || partData.Loading) {
        return <WaitRipple />
    }

    if (partData.Error) {
        return <CoursePartLoadError />
    }

   

    const closePart = useCallback(async (id: string) => {


        const navigateTo = (url?: string) => {

            window.setTimeout(() => {

                if (url) {

                    navigate(url);
                }
                else {
                    navigate(`/`);
                }
            }, 100);
        }

        coursePartService.checkIfPartCanClose(partId!).then(async (res) => {
            if (!res) {
                let result = await coursePartService.closePart(id, true);
                setBlock(false);
                if (result.courseId) {
                    navigateTo(urlService.urlToCourse(result.courseId));
                }
                else if(result.returnId) {
                    navigateTo( urlService.urlToPart( result.returnId! ));
                }
                else{
                    navigateTo( "/" );
                }
            }
            else {
                navigate(urlService.urlToPart(id, "publish"));
            }
        });




    }, [blocker, navigate]);

    const part = partData.Data!;

    const setActive = ({ isActive }: { isActive: boolean }) => isActive ? "active" : "";

    const diffActive = ( diffname:string, isActive:boolean )=>{
        if( isActive || location.pathname.indexOf(diffname) > -1) return "active";
        return "";
    }

    return <>

        <div className={"scrollContent scroller "} aria-live="polite">
            <div className="card-box big-card-box">
                <div className="card-box-title card-title ">
                    <div className='w-100 d-flex align-items-center'>
                        <h3>{part?.Name}</h3>
                        <div className='ml-auto'>
                            <button onClick={(e) => closePart(part.Id)} className="btn btn-link" type="button">
                                <LnIcon name="close" />
                            </button>
                        </div>

                    </div>
                </div>
                <div className="card-box-content card-body">
                    <div className="mb-5 d-print-none">
                        <div className="custom-tab-heading-list" >

                            <NavLink className={setActive} to="texts" >{t.getText("introtext")}</NavLink>
                            <NavLink className={({isActive})=> diffActive("lecturesDiff", isActive) } to="lectures" >{t.getText("lectures")}</NavLink>
                            <NavLink className={({isActive})=> diffActive("filesDiff", isActive) } to="files" >{t.getText("files")}</NavLink>
                            <NavLink className={({isActive})=> diffActive("linksDiff", isActive) } to="links" >{t.getText("links")}</NavLink>
                            <NavLink className={setActive} to="tests" >{t.getText("tests")}</NavLink>
                            <NavLink className={setActive} to="evaluations" >{t.getText("evaluations")}</NavLink>

                            <div className={classes.spacer}>&nbsp;</div>

                            <NavLink className={setActive} to="general" >{t.getText("general")}</NavLink>
                            <NavLink className={setActive} to="print" >{t.getText("print")}</NavLink>
                            <NavLink className={setActive} to="publish" >
                                {t.getText("publish")}
                                {partData?.Data?.Dirty && <LnIcon name="alert" className='icon-small ml-2' />}
                            </NavLink>


                        </div>
                    </div>
                    <main className="">
                        <Outlet />
                    </main>
                </div>
            </div>
        </div>
    </>



}