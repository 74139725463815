import classes from "../../Link/links.module.scss";
import { LinkModel } from "src/coursepart/domain/LinksGroupModel";
import DOMPurify from 'dompurify';

export function ReadOnlyLink(props: { link: LinkModel }) {

    const { link } = props


    return <div key={link.Id} className={`d-flex py-2 mx-4 position-relative border-top-but-first`}>

        <div className={classes.linkInfo + " preserve-white"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( link.Description) }}></div>

        <div className='ml-auto px-3'>
            <a target="_blank" rel="noopener noreferrer nofollow" className="" href={link.Url}>
                {link.Url.split('://').pop()}
            </a>
        </div>

    </div>



}