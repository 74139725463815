import { ChangesState } from "src/coursepart/domain/ChangesState";

export default function GetStateClass(state: ChangesState) {
    switch (state) {
        case ChangesState.added:
            return "bg-primary";
        case ChangesState.deleted:
            return "bg-danger";
        case ChangesState.changed:
            return "bg-warning";
        case ChangesState.moved:
            return "bg-primary-danger-mix-vertical";
        default:
            return "";
    }
}