
import { FormatTimeDurationFromSeconds } from "src/utils/DateTimeFormat";
import { useI18n } from "src/utils/lni18n";
import classes from '../lecture.module.scss';
import LnIcon from 'src/components/LnIcon';
import { EditScreenDto } from "../domain/LectureState";

const ImgSoundFooter = (props: { screen: EditScreenDto, open: ()=>void }) => {

    const { languageService: t } = useI18n();
    const {screen, open} = props;
    
    return (
        <button className={`btn btn-link ${classes.imgAudioButton}`} type="button" onClick={open}>
            <span>

                <LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="volume-black" />
                <LnIcon className={[classes['footer-icon'], 'natural ml-2'].join(' ')} name="img" />

            </span>
            {screen.Duration > 0 &&
                <span className="ml-auto"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDurationFromSeconds(screen.Duration, t)}</span>}
            {!!!screen.Duration && <span className="ml-auto">{t.getText("no.audio")}</span>}
        </button>

    );

}


export default ImgSoundFooter;