import { atom, getDefaultStore } from "jotai";
import { LastPartData } from "src/coursepart/domain/LastPartData";
import lastDataDB, { OpenedCoursesData, OpenedPartsData } from "./db/LastOpenedDB";
import api from "./api/axiosService";
import { CoursePartEditModel } from "src/coursepart/domain/CoursePartEditResponseModel";
import { IdAndNameDto } from "src/types";
import { CourseEditResponse } from "src/course/domain/CourseEditModel";
const store = getDefaultStore();

export const lastCoursePartsAtom = atom<LastPartData[]>([]);
lastCoursePartsAtom.debugLabel = "lastCoursePartsAtom";

export const lastCoursesAtom = atom<IdAndNameDto[]>([]);
lastCoursesAtom.debugLabel = "lastCoursesAtom";

export class LastDataService{
    
    LASTPARTS_DATA_URL =  `/author/lastcourseparts`;

    public async userLoggedIn(userId: string) {
        try {
            this.initCoursesData(userId);
            await this.initCoursePartsData(userId);
        } catch (error) {
            console.error('Failed to initialize user data:', error);
            throw error;
        }
    }

    private async initCoursePartsData(userId: string) {
        const parts = await lastDataDB.courseParts.filter(d => d.userid === userId).toArray();
        parts.sort((a, b) => b.dato.getTime() - a.dato.getTime());

        if (parts && parts.length > 0) {
            const response = await api.post<LastPartData[]>(this.LASTPARTS_DATA_URL, parts.map(p => p.itemid));
            if (response.status === 200) {
                const dict: Record<string, LastPartData> = {};
                response.data.forEach(d => dict[d.Id] = d);
                parts.forEach(p => {
                    const data = dict[p.itemid];
                    p.name = data.Name;
                    p.dirty = data.Dirty;
                    p.connectedWithCourse = data.ConnectedWithCourse;
                });
                await lastDataDB.courseParts.bulkPut(parts);
            }

            store.set(lastCoursePartsAtom, parts
                .map(d => ({
                    Id: d.itemid,
                    Name: d.name,
                    Dirty: d.dirty,
                    ConnectedWithCourse: d.connectedWithCourse
                })));

        }
        else{
            store.set(lastCoursePartsAtom, [] );
        }
    }

    private async initCoursesData(userId: string) {
        
        lastDataDB.courses.filter(d => d.userid === userId).toArray()
        .then(allData => {
            allData.sort((a, b) => b.dato.getTime() - a.dato.getTime());
            store.set(lastCoursesAtom, allData.map(d => ({ Id: d.itemid, Name: d.name })));
        });
    }


    public async removeFromLastCourseParts(partId: string, currentUserId?: string) {

        if( !currentUserId ){ return; }

        let existingRow = await lastDataDB.courseParts.get({ itemid: partId, userid: currentUserId });
        if (existingRow) {
            await lastDataDB.courseParts.delete(existingRow.id!);
        }
        const allData = await lastDataDB.courseParts.filter(d => d.userid === currentUserId).toArray();

        // sort Descending by date
        allData.sort((a, b) => b.dato.getTime() - a.dato.getTime());
        store.set(lastCoursePartsAtom, allData.map(d => ({ Id: d.itemid, Name: d.name, ConnectedWithCourse: d.connectedWithCourse, Dirty: d.dirty })));

    }

    public async updateLastParts(model: CoursePartEditModel, currentUserId?: string) {

        if( !currentUserId ){ return; }

        let existingRow = await lastDataDB.courseParts.get({ itemid: model.Data?.Id, userid: currentUserId });
        if (existingRow) {
            existingRow.name = model.Data!.Name;
            existingRow.connectedWithCourse = model.Data!.ConnectedWithCourse;
            existingRow.dirty = model.Data!.Dirty;

            await lastDataDB.courseParts.update(existingRow.id!, existingRow);
        }

        const allData = await lastDataDB.courseParts.filter(d => d.userid === currentUserId).toArray();
        store.set(lastCoursePartsAtom, allData.map(d => ({ Id: d.itemid, Name: d.name, ConnectedWithCourse: d.connectedWithCourse, Dirty: d.dirty })));

    }

    public async addToLastCourseParts(data: { userid: string, id: string, name: string, connectedToCourse: boolean, dirty: boolean }) {

        let existingRow = await lastDataDB.courseParts.get({ itemid: data.id, userid: data.userid });
        if (existingRow) {
            existingRow.dato = new Date();
            await lastDataDB.courseParts.update(existingRow.id!, existingRow);
        }
        else {

            const newData: OpenedPartsData = {
                itemid: data.id,
                userid: data.userid,
                dato: new Date(),
                name: data.name,
                connectedWithCourse: data.connectedToCourse,
                dirty: data.dirty
            }

            let allrows = await lastDataDB.courseParts.filter(d => d.userid === data.userid).toArray();

            if (allrows.length + 1 > 10) {
                // get the oldest
                allrows.sort((a, b) => a.dato.getTime() - b.dato.getTime());
                const todelete = allrows[0];
                await lastDataDB.courseParts.delete(todelete.id!);
            }

            await lastDataDB.courseParts.add(newData);
        }

        const allData = await lastDataDB.courseParts.filter(d => d.userid === data.userid).toArray();

        // sort Descending by date
        allData.sort((a, b) => b.dato.getTime() - a.dato.getTime());
        store.set(lastCoursePartsAtom, allData.map(d => ({ Id: d.itemid, Name: d.name, ConnectedWithCourse: d.connectedWithCourse, Dirty: d.dirty })));

    }


    public async removeFromLastCourses(courseId: string,currentUserId?: string) {

        if( !currentUserId ){ return; }
        let existingRow = await lastDataDB.courses.get({ itemid: courseId, userid: currentUserId });
        if (existingRow) {
            await lastDataDB.courses.delete(existingRow.id!);
        }
        const allData = await lastDataDB.courses.filter(d => d.userid === currentUserId).toArray();

        // sort Descending by date
        allData.sort((a, b) => b.dato.getTime() - a.dato.getTime());
        store.set(lastCoursesAtom, allData.map(d => ({ Id: d.itemid, Name: d.name })));

    }


    public async addToLastCourses(data: { userid: string, id: string, name: string }) {

        let existingRow = await lastDataDB.courses.get({ itemid: data.id, userid: data.userid });
        if (existingRow) {
            existingRow.dato = new Date();
            await lastDataDB.courses.update(existingRow.id!, existingRow);
        }
        else {

            const newData: OpenedCoursesData = {
                itemid: data.id,
                dato: new Date(),
                name: data.name,
                userid: data.userid
            }

            let allrows = await lastDataDB.courses.filter(d => d.userid === data.userid).toArray();

            if (allrows.length + 1 > 10) {
                // get the oldest
                allrows.sort((a, b) => a.dato.getTime() - b.dato.getTime());
                const todelete = allrows[0];
                await lastDataDB.courses.delete(todelete.id!);
            }

            await lastDataDB.courses.add(newData);
        }

        const allData = await lastDataDB.courses.filter(d => d.userid === data.userid).toArray();

        // sort Descending by date
        allData.sort((a, b) => b.dato.getTime() - a.dato.getTime());
        store.set(lastCoursesAtom, allData.map(d => ({ Id: d.itemid, Name: d.name })));

    }

    public async updateLastCourses(model: CourseEditResponse, currentUserId?: string) {

        if( !currentUserId ){ return; }

        let existingRow = await lastDataDB.courses.get({ itemid: model.Course.Id, userid: currentUserId });
        if (existingRow) {
            existingRow.name = model.Course.Name;

            await lastDataDB.courses.update(existingRow.id!, existingRow);
        }

        const allData = await lastDataDB.courses.filter(d => d.userid === currentUserId).toArray();
        store.set(lastCoursesAtom, allData.map(d => ({ Id: d.itemid, Name: d.name })));

    }


}
export const lastDataService = new LastDataService();
