import { useI18n } from "src/utils/lni18n";
import HtmlTextEditor from 'src/components/TextEditor/HtmlTextEditor';
import React, { useCallback, useState } from 'react';
import { EditTestDataDTO, FiletestDataDto } from '../DomainModels';
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { testDataAtom } from "../TestService";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";

const FileTest = (props: { data: EditTestDataDTO<FiletestDataDto>, onChange: (d: FiletestDataDto) => void }) => {

  const { state } = useLocation();
  const { data, onChange } = props;
  const { languageService: t } = useI18n();
  const [showFiletypes, setshowFiletypes] = useState(data.TestData.UseStandardFileTypes);
  const [generalTestData] = useAtom(testDataAtom);

  const validationSchema = React.useMemo(() => Yup.object({
    Question: Yup.string().required(t.getText('required_field')),
    MaxFileSize: Yup.number().required(t.getText('required_field'))
      .max(data.TestData.MaxAuthorFileSize, t.getText("filetestadmin.maxsizeformaterror.text2", data.TestData.MaxAuthorFileSize.toString()))

  }), [t]);

  const { control, watch, register, formState: { errors } } = useForm({
    defaultValues: data.TestData,
    resolver: yupResolver(validationSchema),
    mode: "onChange"
  });

  const saveData = useCallback((value: FiletestDataDto) => {

    onChange(value);
  }, []);

  React.useEffect(() => {
    const subscription = watch((value:any) => {

      setshowFiletypes(value.UseStandardFileTypes || false);
      saveData(value)
    })
    return () => subscription.unsubscribe();
  }, [watch])


  if (!data) return null;

  return <div className="mt-5">
    <div className='mb-2'>
      <strong>{t.getText("question")} </strong>
      <ErrorMessage errors={errors} name="Question" render={({ message }) => <span className="ml-4 text-danger small font-weight-bold">{message}</span>} />

    </div>

    <Controller
      control={control}
      name="Question"
      render={({ field: { onChange, value } }) => (
        <HtmlTextEditor readOnly={state?.readOnly} value={value} onChange={onChange} initialValue={data.TestData.Question} name="Question"  ></HtmlTextEditor>
      )}
    />

    <div className="mt-4 ">
      <div className='mb-2 font-weight-bold'>
        {t.getText("allowed.file.types")}
      </div>
      <div className="d-flex flex-wrap align-items-center">
        <div className="mr-5">
          <label>
            <input disabled={state?.readOnly} className="mr-3" type="checkbox" {...register("UseStandardFileTypes")} />{t.getText("filetestadmin.filetypes.use.default")}
          </label>
        </div>

        <div className="d-flex align-items-center">
          <div className="white-space-nowrap mr-2">{t.getText("filetestadmin.filetypes.custom")}</div>
          <input disabled={state?.readOnly} className="form-control" type="text" {...register("CustomFileTypes")} />
        </div>

      </div>
      {showFiletypes && <div className="row"><div className="col-lg-6 col-xs-12 "><div className="mt-3 alert alert-grey"><strong>{t.getText("default")}<br /></strong>{generalTestData.fileTypes}</div></div></div>}
    </div>

    <div className="mt-5 row">
      <div className="col-md-4 col-lg-3 col-sm-6">
        <div className='mb-2 font-weight-bold'>
          {t.getText("filetestadmin.lblmaxfilesize")}
        </div>
        <input disabled={state?.readOnly} className="form-control" type="number" {...register("MaxFileSize")} />
      </div>
    </div>

  </div>

}

export default FileTest;

