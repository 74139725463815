import { useCallback, useEffect, useRef, useState } from "react";
import { userErrorAtom, LoginState, userService, LoginResult } from "../services/user/userService"
import { useI18n } from "../utils/lni18n";
import { atom, useAtom } from "jotai";
import {  CurrentUserDto, LoginFormDto } from "../services/user/types";
import dayjs from "dayjs";
import { debouncer } from "src/utils/debouncer";
import DOMPurify from 'dompurify';

export const dataAtom = atom<LoginFormDto>({ FinalDomain: "", FederationText: "", Identifier: "", Password: "", Federated: false, LocalTime: "" });
dataAtom.debugLabel = "dataAtom for login";
const capsAtom = atom(false);
capsAtom.debugLabel = "Caps Atom";

const validAtom = atom((get) => {
    const data = get(dataAtom);
    return data.Identifier.length > 4 && data.Password.length > 4;
});

validAtom.debugLabel = "validAtom";

const LoginForm: React.FC<{ onSubmit: (res: LoginResult) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();
    const [userData, setUserData] = useAtom(dataAtom);
    const [error, setError] = useAtom(userErrorAtom);
    const [capsOn, setCapsOn] = useAtom(capsAtom);
    const [valid] = useAtom(validAtom);

    const [showEmailVerify, setShowEmailVerify] = useState(false);
    const [tempUser, setTempUser] = useState<CurrentUserDto|undefined>(undefined);
    

    const debounce = useRef<debouncer | undefined>(undefined);

    useEffect(() => {
        debounce.current = new debouncer(testFederation, 500);
        return () => debounce.current?.clear();
    }, [])


    const handleIdentityChange = async (e: any) => {
        setUserData(data => { return { ...data, "Identifier": e.target.value, Federated: false, FederationText: "" } });
        setError(null);
        const email = e.target.value;
        debounce.current?.debounce(email);

    };

    const testFederation = async (email: string) => {
        const federationCheck = await userService.checkFederation(email);
        if (federationCheck.result) {
            setUserData(data => { return { ...data, Federated: true, FederationText: federationCheck.text } });
        }
    }

    const handlePasswordChange = (e: any) => {
        setUserData(data => { return { ...data, "Password": e.target.value } })
        setError(null);
    };

    const testCapsLock = useCallback((e: any) => {
        if (e.getModifierState("CapsLock") !== capsOn) {
            setCapsOn(val => !val);
        }
    }, [setCapsOn, capsOn]);

    const sendVerifyEmail = ()=>{
        if( tempUser){
        userService.sendVerifyEmail(tempUser,1).then((message:string|null)=>{
            setShowEmailVerify(false);
            setError({"message": message || "" })
        })
        }
    }

    const submit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (valid) {
            const localtime = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
            var loginData = { ...userData, LocalTime: localtime }
            const res = await userService.loginUser(loginData);
            if (res.state === LoginState.ok ) {
                onSubmit(res);
            }

            if(  res.state === LoginState.requireVerification){
                setTempUser(res.user);
                setShowEmailVerify(true);
            }

        }

    }, [valid, userData]);


    return (
        <form onSubmit={(e) => submit(e)}>
            <div className="form-group">
                <label>{t.getText("alias")}</label>
                <input value={userData.Identifier} required onKeyUp={testCapsLock} autoFocus={true}
                    autoComplete="username" data-testid="inp-alias" type="text"
                    name="Identifier" onChange={(e) => handleIdentityChange(e)} className="input form-control custom-input" />

            </div>

            {userData.Federated && <div className="alert alert-warning preserve-white">{userData.FederationText}</div>}

            {!userData.Federated &&
                <div className="form-group">
                    <label>{t.getText("passwd")}</label>
                    <input value={userData.Password} required onKeyUp={testCapsLock} autoComplete="current-password" type="password"
                        name="Password" onChange={(e) => handlePasswordChange(e)} className="input form-control custom-input" />

                    {error && <>
                        <div className="errorMessage" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( error.message) }} ></div>
                        { showEmailVerify && <button type="button" className="mt-3 btn btn-primary" onClick={sendVerifyEmail} >{t.getText("send.verification.email")}</button> }
                        </>
                    }
                </div>
            }

            {capsOn &&
                <div className="preserve-white errorMessage">{t.getText("caps.lock.on")}</div>
            }

            <button disabled={!valid} type="submit" className="btn btn-primary">{t.getText("login")}</button>

        </form>
    );

}

export default LoginForm;