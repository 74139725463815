import { UniqueIdentifier } from "@dnd-kit/core";
import { ScreenItemStatus } from "src/coursepart/domain/ScreenItemStatus";

export interface LectureState {
    currentLecture: EditLectureDto;
    showTranscript: boolean;
    controlsState: ControlsState;
    volume: number;
    changeDirection: "fromLeft" | "fromRight" | null;
    autoRepeat: boolean;
    autoNext: boolean;
    autoNextTimerId: number;
    doPlay: boolean;
    stoppedAtCuePoint: boolean;
    isPlaying: boolean;
    playbackRate: number;
    printstyle: number;
    showForumDialog: boolean;
    showTestDialog: boolean;
    currentPlayed: number;

}

export interface ControlsState {
    visible: boolean;
    fullscreen: boolean;
    showingMenu: boolean;

}



export interface EditLectureDto {
    Screens: EditScreenDto[];
    Name: string;
    LectureId: string;
    AtFirstScreen: boolean;
    AtLastScreen: boolean;
    CurrentScreenIndex: number;
    CoursePartId: string;
    Description: string;
    PrevName?: string;
    PrevDescription?: string;
    Modified: boolean;
    
}

export interface LectureFilesForPartDto {
    LectureId: string;
    Name: string;
    Files: LectureFileDto[];
}

export interface EditScreenDto {
    EditHtml: boolean;
    ScreenId: string;
    LectureId: string;
    CuePoints: ScreenCuePointDTO[];
    ScreenType: number;
    ThumbnailUrl: string;
    ImageSize: SizeDto;
    ImageUrl?: string;
    AudioUrl?: string;
    VideoUrl?: string;
    Transcript: string;
    MainFileName?: string;
    Html: string;
    Duration: number;
    Order: number
    PrevOrder?: number
    RunningConversion: boolean;
    PptConversion: boolean;
    GotSound: boolean;
    Selected: boolean;
    Status: ScreenItemStatus;
    ErrorText?: string;
}

export interface LectureFileDto {
    Id: number;
    Name: string;
    Url: string;
    Uploaded: string;
}

export interface ImageDto {
    Url: string;
    Hires: boolean;
}

export interface MediaDto {
    Url: string;
    ThumbnailsUrl?: string;
    WithCredentials: boolean;
}

export interface SizeDto {
    Height: number;
    Width: number;
}


export interface ScreenCuePointDTO {
    id: number;
    position: number;
    forum?: CuePointForumData;
    mandatoryTestPassed: boolean;
    test?: string ;
    coursePart: string | null;
    Passed: boolean;
    CuePointID: string | UniqueIdentifier;
    ThreadId: number | null;
    percentPosition: number
}



export interface CuePointForumData {
    Text: string;
    Header: string;
}


export interface ScreenApi {
    seekTo: (pos:number, type:number) => void;
}

export interface ControlsApi {
    Quit: () => void;
}



export interface LectureUpdatTextsCommand {
    LectureId: string;
    Name: string;
    Description: string;
}

export enum ScreenType {
    NotSet,
    Video,
    ImageSound
}