import { useI18n } from "src/utils/lni18n";
import HtmlTextEditor from 'src/components/TextEditor/HtmlTextEditor';
import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { EditTestDataDTO } from '../DomainModels';
import { useLocation } from "react-router-dom";

const Singlequestion = (props: { data: EditTestDataDTO<string>, value: string, onChange: ChangeEventHandler<HTMLTextAreaElement> }) => {

  const { state } = useLocation();
  const { data, onChange, value } = props;
  const [error, setError] = useState<string | null>(null)
  const { languageService: t } = useI18n();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value === "") {
      setError(t.getText('required_field'))
    }
    else {
      setError(null)
      onChange(e);
    }

  }

  if (!data) return null;



  return <div className="mt-5">
    <div className='mb-2 font-weight-bold'>
      {t.getText("question")} {error && <span className="ml-4 text-danger small font-weight-bold">{error}</span>}
    </div>
    <HtmlTextEditor readOnly={state?.readOnly} value={value} onChange={handleChange} initialValue={data.TestData} name="TestData"  ></HtmlTextEditor>
  </div>

}

export default Singlequestion;

