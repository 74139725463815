import { useState, useMemo } from "react";
import classes from './../../Lecture/lecture.module.scss';

import {
    Menu,
    Item,
    useContextMenu,
    ItemParams,
    contextMenu,
    PredicateParams,

} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { onePartatom } from '../../CoursePartService';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import scrapBookService from 'src/components/ScrapBook/ScrapBookService';
import ScrapBookDialog, { ScrapType } from 'src/components/ScrapBook/ScrapBookDialog';
import { usePartPath } from 'src/utils/usePartPath';
import { EditScreenDto } from 'src/coursepart/Lecture/domain/LectureState';
import { oneLectureatom } from 'src/coursepart/Lecture/LectureService';
import screenService from 'src/coursepart/Lecture/Screen/ScreenService';

import { useI18n } from "src/utils/lni18n";
import ReadOnlyScreenLink from "./ReadOnlyScreenLink";


const MENU_ID = "lect-menu-id";


const ViewLecture = () => {

    const { partId, lectureId } = useParams();
    const [isVisibleMenu, setVisibilityMenu] = useState(false);

    const navigate = useNavigate();
    const partPath = usePartPath();

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();

    const [lectureData, setLectureDataFunc] = useAtom(useMemo(() => oneLectureatom(lectureId!), [lectureId]));
    const screens = lectureData?.Screens || [];

    const [multipleSelected, oneSelected] = useMemo(() => {
        const num = screens && screens.filter(s => s.Selected).length;
        return [num > 1, num > 0]
    }, [screens]);



    const [partData] = useAtom(
        useMemo(() => {
            return onePartatom(partId!);
        }, [lectureData]));

    const { show, hideAll } = useContextMenu({
        id: MENU_ID
    });



    const filterForNoscreenSelected = ({ triggerEvent, props, data }: PredicateParams<EditScreenDto, any>) => {
        return !oneSelected;
    }


    function displayContextMenu(e: React.MouseEvent, screen: EditScreenDto) {

        if (!screen.Selected) {
            screenService.SelectScreen(screen, false, false);
        }

        show({
            event: e,
            props: screen
        });
    }

    async function handleItemClick(params: ItemParams<EditScreenDto, any>) {

        const { id, props }: ItemParams<EditScreenDto, any> = params;
        switch (id) {

            case "open":
                playLecture(props!.Order);
                break;


            case "copy":
                if (!!props) {
                    await scrapBookService.addScreen(props!, partId!);
                }
                break;
        }
    }

    const playLecture = (num: number) => {
        navigate(`${num}/view/`);
    }

    const showScrapBook = () => {

        scrapBookService.loadScreens();
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                return <ScrapBookDialog readOnly={true} contextId={lectureId!} type={ScrapType.screen} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });
    }


    return <div className={"scrollContent scroller "} aria-live="polite"> 
        <div className={"card-box big-card-box "}>
            <div className="card-box-title card-title d-flex">
                <div>
                    <h3>
                        {partData.Data?.Name}<br />{lectureData?.Name}

                    </h3>
                    <div>{lectureData?.Description}</div>
                </div>
                <Link className="btn btn-primary btn-sm" to={`${partPath}/lectures`} >{t.getText("back")}</Link>
            </div>
            <div className="card-box-content card-body">
                <div className="d-flex">
                    <button type="button" onClick={() => playLecture(0)} className='ml-3 btn btn-inverse btn-small'>{t.getText("display")}</button>
                    <button type="button" onClick={showScrapBook} className='ml-3 btn btn-primary btn-small'>{t.getText("scrapbook")}</button>
                </div>


                <div className={"mt-5 flex-row flex-wrap " + classes.cardWrapper}>
                    {screens?.map(screen => <ReadOnlyScreenLink playLecture={playLecture} onContextMenu={e => displayContextMenu(e, screen)}  screen={screen} key={screen.ScreenId} />)}
                </div>


                <Menu id={MENU_ID} onVisibilityChange={(v: boolean) => setVisibilityMenu(v)}>

                    <Item id="open" hidden={filterForNoscreenSelected} onClick={handleItemClick} >
                        {t.getText("open")}
                    </Item>

                    <Item id="copy" hidden={filterForNoscreenSelected} onClick={handleItemClick} >
                        {t.getText("copy")}
                    </Item>


                </Menu>


            </div>
        </div>
    </div>

}

export default ViewLecture;
