import { useMemo } from "react";
import { onePartatom } from "../../CoursePartService";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import LinkGroupDiff from "./LinkGroupDiff";
import { LinksGroupModelDiff } from "src/coursepart/domain/LinksGroupModel";

const LinksDiff = (props:{ linkGroups: LinksGroupModelDiff[] } ) => {
    const { partId } = useParams();

    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const {linkGroups} = props;

    if (!partData || !linkGroups) {
        return null;
    }
    return (
        <div className="max-col-width">

            <div role="list">
                {linkGroups.map(item => <div role="listitem" key={item.Id} className="mb-4" >
                    <LinkGroupDiff partId={partId!} group={item} />
                </div>)}
            </div >


        </div>
    )
}

export default LinksDiff;
