export const API_URL:string = import.meta.env.VITE_API_URL;
export const PUBLIC_URL:string = import.meta.env.VITE_PUBLIC_URL;
export const PORTAL_API_URL:string = import.meta.env.VITE_PORTAL_API_URL;
export const PORTAL_URL:string = import.meta.env.VITE_PORTAL_URL;
export const API_HOST = API_URL?.replace(/\/api$/, "");
export const PROD = import.meta.env.PROD;


export const flagUrls: Record<string, string> = {
    "da-dk": '/imgs/flag_da.svg',
    "en-gb": '/imgs/flag_en.svg',
    "sv-se": '/imgs/flag_sv.svg'
  }

let _release = null;
const meta:any = document?.querySelector('meta[name="author-release"]');
if (meta && PROD) {
  _release = meta["content"];
}

if (_release === "%VERSION%") {  // localhost
  _release = null;
}
export const release = _release;