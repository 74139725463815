import { LnCollapseGroup } from "src/components/LnCard/LnCollapseGroup";
import LnIcon from "src/components/LnIcon";
import { ChangesState } from "src/coursepart/domain/ChangesState";
import { LinksGroupModelDiff } from "src/coursepart/domain/LinksGroupModel";
import LinkCardDiff from "./LinkCardDiff";
import GetStateClass from "src/utils/StateColors";


export default function LinkGroupDiff(props: {
    group: LinksGroupModelDiff, partId: string
}) {

    const { group, partId } = props;

    const name = <div className="d-flex align-items-center"> <div dangerouslySetInnerHTML={{__html:group.Name}}></div>{group.Links.length > 0 ? <LnIcon name="slides" className="icon-small ml-3" /> : <></>}</div>;

    let bgstate = GetStateClass(group.State);

    return <LnCollapseGroup bgclass={bgstate} groupName={name} isOpen={false}   >
        <div>
            {group.Links.map((item) => (
                    <LinkCardDiff key={item.Id} link={item} />
            ))}
        </div>
    </LnCollapseGroup>;



}