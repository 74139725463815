import { LinkModelDiff } from 'src/coursepart/domain/LinksGroupModel';
import GetStateClass from 'src/utils/StateColors';
import DOMPurify from 'dompurify';

const LinkCardDiff = (props: { link: LinkModelDiff }) => {

    const { link } = props;

    if (!link) {
        return null;
    }


   const bgstate = GetStateClass( link.State );

    return <div key={link.Id} className={`d-flex py-2 mx-4 position-relative border-top-but-first`}>
        <div className={`cardLeftIndicator ${bgstate}`}></div>

        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( link.Description) }}></div>

        <div className='ml-auto px-3'>
            <a href={link.Url} rel="noopener noreferrer nofollow" target='_blank'>{link.Url}</a>
        </div>

       
    </div>

}

export default LinkCardDiff;
