import { useI18n } from "src/utils/lni18n";
import classes from "../Print.module.scss";
import { FormatTimeDuration } from "src/utils/DateTimeFormat";
import { CoursePrintDTO } from "src/course/domain/Print";
import PrintPartComponent from "../CoursePart/PrintPartComponent";
import LnIcon from "src/components/LnIcon";
import DOMPurify from 'dompurify';

const PrintCourseComponent = (props: { printData: CoursePrintDTO }) => {

  const { printData } = props;
  const { languageService: t } = useI18n();

  if (!printData) return null;

  return (

    <div className={classes.print}>
      <h3 className="my-3">{t.getText("description")}</h3>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( printData.Description) }}></div>

      <h3 className="my-3">{t.getText("duration")}</h3>
      <div className="pl-3">
        <div>
          {t.getText("video")}: {FormatTimeDuration(printData.TotalLecturesDuration.VideosDuration, t)}
        </div>
        <div>
          {t.getText("sound")}: {FormatTimeDuration(printData.TotalLecturesDuration.SoundsDuration, t)}
        </div>
      </div>

      <div>
        {printData.Themes.map( t => <div className="pt-5 mt-4" key={t.ID}>
          <h2 className="">
            <LnIcon name="book" className="mr-3" />
            {t.Name}
            </h2>
          <div className="mt-3 ml-1 pl-5 border-left border-primary border-w2 ">
            {t.CourseParts.map( p => <div className="mb-5" key={p.ID}>
              <h2 className="border-bottom border-w2 border-primary">{p.Name}</h2>
              <PrintPartComponent printData={p} />
            </div> )}
          </div>
        </div> )}
      </div>

    </div>

  )
}

export default PrintCourseComponent;
