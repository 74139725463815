import { useMemo } from "react";
import { useI18n } from "src/utils/lni18n";
import { useAtom } from "jotai";
import courseService, { currentCourseAtom, oneCourseatom } from "src/course/CourseService";
import { CourseAddOnDTO, CourseAddonFormDTO } from "../domain/CourseAddonDTO";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import EditAddonDialog from "./EditAddonDialog";
import DOMPurify from 'dompurify';


const CourseAddons = () => {

  const [courseId] = useAtom(currentCourseAtom);
  const courseAtom = useMemo(() => oneCourseatom(courseId || ""), [courseId]);
  const [courseData] = useAtom(courseAtom);
  const dialogPortal = useDialog();

  const { languageService: t } = useI18n();


  function setAddon(checked: boolean, addon: CourseAddonFormDTO) {
    courseService.setAddon(courseId!, addon.Id, checked);
  }

  function addAddon() {
    editAddon( { Id: 0, Header: "", Description: "",Link: ""  } )
  }

  function editAddon(a: CourseAddOnDTO) {
    dialogPortal({
      factory: (onSubmit, onCancel) => {

        return <EditAddonDialog addOn={a} onCancel={onCancel} onSubmit={onSubmit} />
      },
      size: "md"
    }).then((data: CourseAddOnDTO) => {
      courseService.saveAddon(courseId!, data);
    });
  }

  return (
    <div className="mb-5">
      <div className="d-print-none">
        <h3 className="mb-3">{t.getText("courseaddons")}</h3>

        <div className="mb-3 d-flex">
          <div className="flex-grow-1 d-flex flex-wrap">
            {courseData.Course.AddOns.map(a =>
              <div key={a.Id} className="mb-3">
                <label className="mx-3">
                  <input type="checkbox" value="true" checked={a.Selected} onChange={(e) => setAddon(e.currentTarget.checked, a)} />
                  {a.Header}
                </label>

              </div>
            )}
          </div>
          <button onClick={() => addAddon()} className="ml-auto btn btn-primary  btn-small" >{t.getText("add")}</button>
        </div>

        {courseData.Course.AddOns.filter(a => a.Selected).map(a =>
          <div key={a.Id} className="mb-4 border-top pt-3">
            <h3>{a.Header}</h3>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( a.Description) }}></div>
            <div className="d-flex">
              <a href={a.Link} target="_blank" rel="noopener noreferrer nofollow" >{a.Link}</a>
              <button onClick={() => editAddon(a)} className="ml-auto btn btn-primary  btn-small" >{t.getText("edit")}</button>
            </div>
          </div>
        )}


      </div>


    </div>
  )
}

export default CourseAddons;
