
import { FormatTimeDurationFromSeconds } from "src/utils/DateTimeFormat";
import { useI18n } from "src/utils/lni18n";
import classes from '../lecture.module.scss';
import LnIcon from 'src/components/LnIcon';
import { EditScreenDto } from "../domain/LectureState";

const VideoFooter = (props: { screen: EditScreenDto }) => {

    const { languageService: t } = useI18n();
    const { screen } = props;

    return (
        <>
            <span>
                <LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="video" />
            </span>
            {screen.Duration > 0 &&
                <span className="ml-auto"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDurationFromSeconds(screen.Duration, t)}</span>}
        </>

    );

}


export default VideoFooter;