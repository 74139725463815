import { LnCollapseGroup } from "src/components/LnCard/LnCollapseGroup";
import { FilesGroupModel } from "src/coursepart/domain/FilesGroupModel";
import ReadOnlyFileCard from "./ReadOnlyFileCard";
import LnIcon from "src/components/LnIcon";



export default function ReadOnlyFileGroup(props: {
    group: FilesGroupModel, partId: string
}) {
    const { group, partId } = props;

    const name = <div className="d-flex align-items-center"> {group.Name} {group.Files.length > 0 ? <LnIcon name="slides" className="icon-small ml-3" /> : <></>}</div>

    const panel = <LnCollapseGroup groupName={name} isOpen={false} >
        <div>
            {group.Files.map((item) => (
                <ReadOnlyFileCard partId={partId} key={item.Id} file={item} />
            ))}
        </div>

    </LnCollapseGroup>;


    return <div id={"g_" + group.Id} role="listitem" key={group.Id} className="mb-4" >
        {panel}
    </div>;

}