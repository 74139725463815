import api from "../services/api/axiosService";
import { focusAtom } from 'jotai-optics'
import { atom, getDefaultStore } from "jotai";
import { CoursePartEditModel, CoursePartEditResponse } from "./domain/CoursePartEditResponseModel";
import { OpticFor_ } from "optics-ts";
import { SavePartTextDto, TextEditTypes } from "./domain/SavePartTextDto";
import { produce } from "immer";
import { LectureDtoWithOriginals } from "./domain/LectureDtoWithOriginals";
import { AddLectureData } from "./domain/AddLectureData";
import lectureService from "./Lecture/LectureService";
import { AddFileModel } from "./domain/AddFileModel";
import fileUploadservice from "src/components/FileUpload/FileUploadService";
import { CompleteMultipartUploadCommandOutput } from "@aws-sdk/client-s3";
import { AddFileRequest } from "./domain/AddFileRequest";
import { FileModel, FileModelDiff, FilesGroupModel, FilesGroupModelDiff } from "./domain/FilesGroupModel";
import { LinkModel, LinksGroupModel, LinksGroupModelDiff } from "./domain/LinksGroupModel";
import { LinkFormDTO } from "./domain/LinkFormDTO";
import { LectureFileDto, LectureUpdatTextsCommand } from "./Lecture/domain/LectureState";
import { debouncer } from "src/utils/debouncer";
import { CopyLectureCommand } from "./Lecture/domain/CopyLectureCommand";
import { IdAndNameAndSelectDto, IdAndNameDto, LockReason } from "src/types";
import { PublishRequestResponse } from "./domain/PublishRequestResponse";
import { PublishResponse } from "./domain/PublishResponse";
import { EvalEditAnswerOptionDTO, EvalQuestionType, EvaluationQuestionDTO } from "./domain/EvaluationEditDataDTO";
import { CopyPartData } from "./Dialogs/CopyDialog";
import coursePartCollectionService from "./CoursePartCollectionService";
import { AxiosError } from "axios";
import languageService from "src/utils/languageService";
import { TinyAuthor } from "./domain/TinyAuthor";
import { CoursePartHiddenTests } from "./domain/PublishPartViewDataDTO";
import { CoursePartPrintDTO } from "../Print/CoursePart/domain";
import { selectedAtom } from "src/utils/SelectedAtom";
import courseService, { currentCourseAtom } from "src/course/CourseService";
import { CoursePartWithConnectedDTO } from "./domain/GetCoursepartsResponse";
import { waitRippleservice } from "src/services/WaitRippleService";
import coursePartPingService from "./CoursePartPingService";
import { urlService } from "src/services/NavService";
import { currentUserAtom } from "src/services/user/userService";
import { LectureFilePostDto } from "./Lecture/domain/LectureFilePostDto";
import { TextDiffResponse } from "./domain/TextDiffResponse";
import screenEncodingService from "./Lecture/Screen/ScreenEncodingService";
import { lastDataService } from "src/services/LastDataService";
import { LinkDiffResponse } from "./domain/LinkDiffResponse";
import { FileDiffResponse } from "./domain/FileDiffResponse";
import { TestsDiffResponse } from "./domain/TestsDiffResponse";


const store = getDefaultStore();

export const openPartsDataAtom = atom<Record<string, CoursePartEditModel>>({});
openPartsDataAtom.debugLabel = "openPartsDataAtom";

export const onePartatom = (id: string) => {
    const atom = focusAtom(
        openPartsDataAtom,
        (optic: OpticFor_<Record<string, CoursePartEditModel>>) => optic.prop(id)
    )
    atom.debugLabel = "part: " + id;
    return atom;
};

export const oneLectureAtom = (partId: string, lectureId: string) => {
    return atom(get => {
        const part = get(onePartatom(partId));
        return part.Data?.Lectures.find(l => l.Id === lectureId);
    })
};






export const partsListAtom = atom((get) => {

    const parts = get(openPartsDataAtom);
    const selected = get(selectedAtom);
    const partList: CoursePartWithConnectedDTO[] = Object.keys(parts)
        .map(id => parts[id])
        .filter(d => !!d.Data).map(d => {
            const data = d.Data;
            return {
                Id: data!.Id,
                Name: data!.Name,
                Selected: selected.coursePart === data!.Id || false,
                Dirty: data!.Dirty,
                OpenFromCourse: d.OpenFromcourse,
                ConnectedWithCourse: data?.ConnectedWithCourse || false

            }
        });

    return partList;
});

partsListAtom.debugLabel = "partsListAtom";


export const authorsAtom = atom<IdAndNameDto[]>([]);
authorsAtom.debugLabel = "authorsAtom";

const endPoints = {
    GET_PART: (partId: string, forceUnlock?: boolean) => `/author/coursepart/${partId}${forceUnlock ? "?forceUnlock=true" : ""}`,
    GET_PART_LECTURE_ORIGINALS: (partId: string) => `/author/coursepart/${partId}/lectureFiles`,
    CLOSE_ALL_PARTS: () => `/author/coursepart/EndEditAll`,
    CLOSE_PART: (id: string) => `/author/coursepart/${id}/EndEdit`,
    COPY_PROTECT_PART: (id: string, state: boolean) => `/author/coursepart/${id}/${state}/copyProtect`,
    CREATE_PART: () => `/author/coursepart/newPart`,
    COPY_PART: (id: string) => `/author/coursepart/${id}/copy`,
    RESET_PART: (id: string, includeLects: boolean) => `/author/coursepart/${id}/${includeLects}/reset`,
    DELETE_PART: (id: string) => `/author/coursepart/${id}/delete`,
    CATEGORY_PART_SET: (id: string, catId: number, state: boolean) => `/author/coursepart/${id}/category/${catId}/${state}/set`,
    SAVE_TEXT: `/author/coursepart/savetext`,

    AUTHORS_OF_PARTS: `/author/coursepart/authors/list`,
    PARTS_BY_AUTHOR: (userId: string) => `/author/courseparts/find/${userId}/byauthor`,


    PRINT_PART: (id: string, edit: boolean) => `/author/coursepart/${id}/${edit}/print`,

    PUBLISH_PART_REQUEST: (id: string) => `/author/coursepart/${id}/PublishRequested`,
    PUBLISH_PART: (id: string) => `/author/coursepart/${id}/Publish`,

    SET_LINKGROUP_DATA: (coursePartID: string) => `/author/coursepart/${coursePartID}/link/UpdateLinks`,
    SET_FILEGROUP_DATA: (coursePartID: string) => `/author/coursepart/${coursePartID}/file/UpdateFiles`,

    REORDER_LECT: (coursePartID: string) => `/author/coursepart/${coursePartID}/SetLecturesOrder`,
    ADD_LECT: (coursePartID: string) => `/author/coursepart/${coursePartID}/addlecture`,
    COPY_LECT: (coursePartID: string) => `/author/coursepart/${coursePartID}/copylecture`,
    DELETE_LECT: (coursePartID: string, lectureId: string) => `/author/coursepart/${coursePartID}/lecture/${lectureId}/deletelecture`,

    ADD_FILE: (coursePartId: string) => `/author/coursepart/${coursePartId}/file/add`,
    DELETE_FILE: (partId: string, fileId: string) => `/author/coursepart/${partId}/file/${fileId}/DeleteFile`,
    ADD_FILEGROUP: (coursePartId: string) => `/author/coursepart/${coursePartId}/file/addFileGroup`,
    DELETE_FILEGROUP: (coursePartId: string, groupId: string) => `/author/coursepart/${coursePartId}/file/group/${groupId}/delete`,

    ADD_LINK: (coursePartId: string) => `/author/coursepart/${coursePartId}/link/add`,
    DELETE_LINK: (partId: string, linkId: string) => `/author/coursepart/${partId}/link/${linkId}/DeleteLink`,
    ADD_LINKGROUP: (coursePartId: string) => `/author/coursepart/${coursePartId}/link/addLinkGroup`,
    DELETE_LINKGROUP: (coursePartId: string, groupId: string) => `/author/coursepart/${coursePartId}/link/group/${groupId}/delete`,

    EVAL_ORDER_QUESTIONS: (partId: string) => `/author/coursepart/${partId}/evaluations/orderQuestions`,
    EVAL_ADD_QUESTION: (partId: string, type: EvalQuestionType) => `/author/coursepart/${partId}/evaluations/question/${type}/create`,
    EVAL_DELETE_QUESTION: (partId: string, questionId: number) => `/author/coursepart/${partId}/evaluations/question/${questionId}/delete`,
    EVAL_FIND_PARTS: (partId: string, text: string) => `/author/coursepart/${partId}/evaluations/findparts?text=${text}`,
    EVAL_COPY_FROM_PART: (partId: string, srcPartId: string) => `/author/coursepart/${partId}/evaluations/${srcPartId}/copyEvals`,
    EVAL_ORDER_OPTIONS: (partId: string, qid: number) => `/author/coursepart/${partId}/evaluations/question/${qid}/orderoptions`,
    EVAL_DELETE_OPTION: (partId: string, qid: number, optionId: number) => `/author/coursepart/${partId}/evaluations/question/${qid}/${optionId}/deleteOption`,
    EVAL_CREATE_OPTION: (partId: string, qid: number, havescrores: boolean) => `/author/coursepart/${partId}/evaluations/question/${qid}/${havescrores}/createOption`,
    EVAL_QUESTION_SAVE: (partId: string, qid: number) => `/author/coursepart/${partId}/evaluations/question/${qid}`,

    TEXT_DIFF: (partId: string) => `/author/coursepart/${partId}/diff/text`,
    LECTURES_DIFF: (partId: string) => `/author/coursepart/${partId}/diff/lectures`,
    FILES_DIFF: (partId: string) => `/author/coursepart/${partId}/diff/files`,
    LINKS_DIFF: (partId: string) => `/author/coursepart/${partId}/diff/links`,
    TESTS_DIFF: (partId: string) => `/author/coursepart/${partId}/diff/tests`,


    // original lecture files
    ORIGINAL_FILE: (lectureId: string) => `/author/coursepart/lecture/${lectureId}/file`,
    ORIGINAL_FILE_DELETE: (lectureId: string, fileId: number) => `/author/coursepart/lecture/${lectureId}/file/${fileId}/delete`,
}


export class CoursePartService {
   
  
    saveNameDebouncer: debouncer;

    private dirtyGui: (partId: string, name: string) => Promise<boolean> = async (partId: string, name: string) => false;

    constructor() {
        this.saveNameDebouncer = new debouncer(this.savePartName, 500);
    }

    public setDirtyGui(cb: (partId: string, name: string) => Promise<any>) {
        this.dirtyGui = cb;
    }

    public checkIfPartCanClose(partId: string) {

        if (this.dirtyGui) {

            const name = store.get(onePartatom(partId))?.Data?.Name || "";
            return this.dirtyGui(partId, name);
        }
        return Promise.resolve(false);
    }

    public purgePartOpenFromCourse() {
        const parts = store.get(openPartsDataAtom);
        const idsToPurge: string[] = []
        Object.keys(parts).forEach(id => {
            if (parts[id].OpenFromcourse) {
                idsToPurge.push(id);
            }
        });
        if (idsToPurge.length > 0) {
            idsToPurge.forEach(id => this.closePart(id, true));
            return true;
        }

        return false;
    }

    public getLastUrl(id: string) {
        const atom = onePartatom(id);
        if (atom) {
            let partData = store.get(atom);
            return partData.LastUrl || urlService.urlToPart(id);
        }
    }

    public async fetchAuthors() {
        const authors = store.get(authorsAtom);
        if (authors.length > 0) {
            return authors;
        }

        try {
            const response = await api.get(endPoints.AUTHORS_OF_PARTS);
            if (response.status === 200) {
                store.set(authorsAtom, response.data);
                return response.data;
            }
            return null;
        } catch (error: any) {
            console.error(error);
            return null;
        }
    }

    public async getPartsByAuthor(id: string) {
        try {
            const result = await api.get<IdAndNameAndSelectDto[]>(endPoints.PARTS_BY_AUTHOR(id));
            if (result.status === 200) {
                return result.data;
            }
            else {
                return [];
            }

        } catch (error: any) {
            console.error(error);
            return [];

        }
    }


    public async fetchPrintData(partId: string, types: number[], edit: boolean) {
        const response = await api.post<CoursePartPrintDTO>(endPoints.PRINT_PART(partId, edit), types);
        if (response && response.data) {
            this.MutatePartState(partId, (state) => {
                state.PrintData = response.data;
            }, true)
        }

    }

    clearPrintData(partId: string) {
        this.MutatePartState(partId, (state) => {
            state.PrintData = undefined;
        }, true)
    }

    closeParts(partIds: string[]) {
        const parts = store.get(openPartsDataAtom);
        partIds.forEach(id => {
            if (parts[id]) {
                this.closePart(id, true);
            }
        })
    }

    public async closeAllParts(force?: boolean) {

        if (!force && this.partsDirty()) return false;

        coursePartPingService.clear();

        await api.post(endPoints.CLOSE_ALL_PARTS(), null);
        store.set(openPartsDataAtom, {});
        return true;
    }

    public async closePart(partId: string, force?: boolean) {
        const openPartsData = { ...store.get(openPartsDataAtom) };
        screenEncodingService.clearScreens(partId);
        let returnData: { returnId?: string, confirm: boolean, courseId?: string } = { returnId: "", confirm: false };

        if (openPartsData[partId]?.Data) {

            if (!force && openPartsData[partId]!.Data!.Dirty) {
                returnData.confirm = true;
                return returnData;
            }

            await api.post(endPoints.CLOSE_PART(partId), null);
            const data = this.clearPartData(partId);
            returnData.courseId = data.courseId;
            returnData.returnId = data.partId;
        }

        return returnData;
    }

    public isPartDirty(partId: string) {
        const part = store.get(onePartatom(partId));
        return part && part.Data && !part.Data.Locked && part.Data.Dirty
    }

    public partsDirty() {
        const parts = store.get(partsListAtom);
        return parts.find(p => p.Dirty);
    }

    public setDataUrl(partId: string, url: string) {
        this.MutatePartState(partId, (model => {
            model.LastUrl = url;
        }), true);
    }



    public MutatePartState(partId: string, method: (state: CoursePartEditModel) => void, notMakeDirty?: boolean) {

        const atom = onePartatom(partId);
        let state = store.get(atom);

        if (state === undefined) return;

        const newstate = produce(state, (draft) => {
            method(draft);
            if (!notMakeDirty) {
                draft.Data!.Dirty = true;
                courseService.setPartDirty(partId, true);
            }
        })

        store.set(atom, newstate);

        if (newstate.Data) {
            lastDataService.updateLastParts(newstate, store.get(currentUserAtom)?.Id);
        }


    }


    public async setCopyProtected(partId: string, checked: boolean) {
        const response = await api.post(endPoints.COPY_PROTECT_PART(partId, checked), null);

        if (response && response.status === 200) {
            this.MutatePartState(partId, part => part.Data!.CopyProtected = checked);
        }
    }

    public async createPart(text: string) {

        try {
            waitRippleservice.setState(true);
            const response = await api.post<IdAndNameDto>(endPoints.CREATE_PART(), { Value: text });
            if (response && response.status === 200) {
                coursePartCollectionService.addPart(response.data);
                return response.data;
            }

            return null;
        }catch(error: any) {
            console.error(error);
            return null;
        } finally {
            waitRippleservice.setState(false);
        }
    }

    public async deletePart(partId: string) {
        const response = await api.delete(endPoints.DELETE_PART(partId));
        if (response && response.status === 200) {
            coursePartCollectionService.removePart(partId);
            const currentCourse = courseService.partDeleted(partId);
            const nextPartId = this.clearPartData(partId);
            lastDataService.removeFromLastCourseParts(partId, store.get(currentUserAtom)?.Id);
            return { partId: nextPartId.partId, currentCourse: currentCourse };
        }

        return null;
    }

    public async copyPart(partId: string, data: CopyPartData) {

        const response = await api.post<IdAndNameDto>(endPoints.COPY_PART(partId), data);
        if (response && response.status === 200) {
            coursePartCollectionService.addPart(response.data);
            return response.data;
        }

        return null;
    }

    public async setCategory(partId: string, Id: number, checked: boolean) {
        const response = await api.put(endPoints.CATEGORY_PART_SET(partId, Id, checked));

        if (response && response.status === 200) {
            this.MutatePartState(partId, part => {
                part.Data!.Categories.forEach(c => {
                    if (c.Id === Id) c.Checked = checked;
                });
            })
        }
    }

    public clearPartData(partId: string) {

        coursePartPingService.removePartToCheck(partId);

        let returnId: string | undefined = undefined;
        const partData = store.get(openPartsDataAtom);
        const selected = store.get(selectedAtom);

        let currentCourseId: string | undefined = undefined;

        const part = partData[partId];
        if (!part) return { partId: undefined, courseId: undefined };

        if (partData[partId].OpenFromcourse) {
            currentCourseId = store.get(currentCourseAtom);
        }

        if (selected.coursePart === partId) {
            returnId = Object.keys(partData).find(k => k !== partId);
            if (returnId) {
                store.set(selectedAtom, { coursePart: returnId });
            }
            else {
                store.set(selectedAtom, {});
            }
        }
        else {
            returnId = selected.coursePart;
        }

        const newData = produce(partData, data => {
            delete data[partId];
        });
        store.set(openPartsDataAtom, newData);

        return { partId: returnId, courseId: currentCourseId };
    }

    public async resetPart(partId: string, includeLects: boolean) {
        try {
            waitRippleservice.setState(true);
            const response = await api.post<void>(endPoints.RESET_PART(partId, includeLects), null);
            if (response && response.status === 200) {
                await this.loadPart({ partId, forceReload: true });
                return true;
            }
            return false;
        }
        finally {
            waitRippleservice.setState(false);
        }
    }

    public publishedParts(partIds: string[]) {
        var allData = store.get(openPartsDataAtom);
        var selected = store.get(selectedAtom)
        partIds.forEach(key => {
            const part = allData[key];
            if (part.Data && (!part.OpenFromcourse || selected.coursePart === key)) {
                this.loadPart({ partId: key, forceReload: true });
            }
        })

    }

    public async loadPart(params: { partId: string, forceReload?: boolean, forceUnlock?: boolean, openFromCourse?: boolean }) {
        const partData = { ...store.get(openPartsDataAtom) };
        const currentUser = store.get(currentUserAtom);
        const { partId, forceReload, forceUnlock, openFromCourse } = params;
        try {
            if (forceReload || !partData[partId]?.Data || partData[partId]?.Loading) {
                try {
                    waitRippleservice.setState(true);
                    partData[partId] = { PrintData: undefined, Loading: true, Error: undefined, Data: undefined, LastUrl: `/coursepart/${partId}/texts` }
                    store.set(openPartsDataAtom, partData);

                    const response = await api.get<CoursePartEditResponse>(endPoints.GET_PART(partId, forceUnlock))
                    if (response && response.status < 300) {
                        if (response.data.Status === true) {

                            const tobeHandled = produce(partData, toChange => {
                                const newData = toChange[partId];
                                newData.Loading = false;
                                newData.Data = response.data.Model;
                                newData.OpenFromcourse = openFromCourse;
                            });
                            store.set(openPartsDataAtom, tobeHandled);
                            tobeHandled[partId].Data?.Lectures.forEach(l => lectureService.ClearLectureData(l.Id));

                            if (!forceReload) {
                                store.set(selectedAtom, { coursePart: partId });
                            }

                            //this.purgeOpened(partId);

                            if (openFromCourse) {

                                courseService.openThemeWithThisPart(partId);
                            }
                            const part = tobeHandled[partId];
                            this.addToLastData(part, currentUser!.Id);

                            if (response.data.Model.Locked && response.data.Model.Locked.Reason === LockReason.InEncoding) {
                                coursePartPingService.addPartToCheck(response.data.Model.Id);
                            }

                            return part;
                        }
                        else {
                            const dummyData
                                = this.setDummyData(
                                    response.data.CanBeForcedToOpen,
                                    response.data.Message,
                                    partId, openFromCourse, partData);

                            return dummyData;
                        }
                    }
                }
                catch (error: any) {
                    // to be handled in the catch block below
                    throw error;
                }
                finally {
                    waitRippleservice.setState(false);
                }
            }
            else {
                store.set(selectedAtom, { coursePart: partId });
                const part = partData[partId];

                if (openFromCourse === true) {
                    this.MutatePartState(partId, (draft) => { draft.OpenFromcourse = true; }, true);
                }
                this.addToLastData(part, currentUser!.Id);
                return part;
            }

            throw new Error("data not found");
        }
        catch (error: any) {
            const newData = partData[partId];
            let message = "";
            if (error.name === "AxiosError") {
                const axErr = error as AxiosError;
                if (axErr.response?.status === 404) {
                    message = languageService.getText("coursepart.not.found.on.server")
                }

                if (axErr.response?.status === 403) {
                    message = languageService.getText("no.permission")
                }
            }
            else {
                message = error.message || languageService.getText("generalerror");
            }


            newData.Error = {
                header: "",
                message: message,
                when: new Date()
            };
            newData.Loading = false;
            store.set(openPartsDataAtom, partData);

            return newData;
        }
    }

    private setDummyData(canBeForcedToOpen: boolean = false, message: string, partId: string, openFromCourse: boolean | undefined, partData: { [x: string]: CoursePartEditModel; }) {
        const dummyData: CoursePartEditModel = {
            PrintData: undefined,
            CanBeForcedToOpen: canBeForcedToOpen,
            Loading: false,
            Error: {
                header: languageService.getText("locked"),
                message: message, when: new Date()
            },
            Data: undefined,
            LastUrl: `/coursepart/${partId}/texts`,
            OpenFromcourse: openFromCourse
        };

        partData[partId] = dummyData;

        store.set(openPartsDataAtom, partData);
        return dummyData;
    }

    public async purgeOpened(partId: string) {
        const partList = store.get(partsListAtom);
        partList.filter(p => p.Id !== partId).forEach(p => this.closePart(p.Id, true));
    }

    public savePartName(data: SavePartTextDto) {
        api.post<void>(endPoints.SAVE_TEXT, data);
        courseService.partNameUpdated(data.Partid, data.Html);
    }

    public async updatePartName(value: string, partId: string) {
        const data: SavePartTextDto = {
            Partid: partId,
            Html: value,
            Type: TextEditTypes.ItemName
        }

        this.saveNameDebouncer.debounce(data);

        this.MutatePartState(partId, (newModel: any) => {
            newModel.Data.Name = value;
        });
    }


    public addAuthor(partId: string, user: TinyAuthor) {
        this.MutatePartState(partId, state => {
            if (!state.Data!.Authors.find(a => a.Id === user.Id)) {
                state.Data!.Authors.push(user);
            }
        }, true)
    }

    public removeAuthor(partId: string, userId: string) {
        this.MutatePartState(partId, state => {
            state.Data!.Authors = state.Data!.Authors.filter(u => u.Id !== userId);
        }, true)
    }


    /* texts ******************************** */

    public async saveText(partId: string, textName: TextEditTypes, html: string) {


        const data: SavePartTextDto = {
            Partid: partId,
            Html: html,
            Type: textName
        }
        await api.post<void>(endPoints.SAVE_TEXT, data);

        this.MutatePartState(partId, (newModel: any) => {
            newModel.Data[textName] = html;
        })
    }

    /* Publish *********************************** */

    public async publishPart(partId: string, hiddenTests: CoursePartHiddenTests[]) {

        const response = await api.post<PublishResponse>(endPoints.PUBLISH_PART(partId), hiddenTests);
        if (response.status === 200) {

            courseService.setPartDirty(partId, false);

            if (response.data.isAsync) {
                this.MutatePartState(partId, (draft) => {
                    draft.Data = response.data.model;
                    const lockInfo = response.data.LockInfo || {
                        LockedBy: {
                            FirstName: "LäraNära",
                            LastName: "System",
                            Id: "00000000-0000-0000-0000-000000000007"
                        },
                        Reason: LockReason.InEncoding
                    };
                    draft.Data!.Locked = lockInfo;
                    draft.Data!.Dirty = false;
                    draft.Data.NewTests = [];

                });

                coursePartPingService.addPartToCheck(partId);
            }
            else {
                this.MutatePartState(partId, (draft) => {
                    draft.Data = response.data.model;
                    draft.Data.Dirty = false;
                    draft.Data.NewTests = [];
                }, true);
            }
        }

    }

    public async publishPartRequest(partId: string) {
        try {
            const response = await api.get<PublishRequestResponse>(endPoints.PUBLISH_PART_REQUEST(partId));
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /* lectures ******************************** */


    incrementNumOfScreens(partId: string, lectureId: string) {
        this.MutatePartState(partId, draft => {
            const lecture = draft.Data!.Lectures.find(l => l.Id === lectureId);
            if (lecture) {
                lecture.NumOfScreens += 1;
            }
        });
    }

    decrementNumOfScreens(partId: string, lectureId: string) {
        this.MutatePartState(partId, draft => {
            const lecture = draft.Data!.Lectures.find(l => l.Id === lectureId);
            if (lecture) {
                lecture.NumOfScreens -= 1;
            }
        });
    }


    public async selectLecture(partId: string, lect: LectureDtoWithOriginals, forcestate?: boolean) {
        if (!lect) return;

        this.MutatePartState(partId, draft => {
            const lecture = draft.Data!.Lectures.find(l => l.Id === lect.Id)!;
            if (lecture) {
                const state = lecture.Selected;
                draft.Data!.Lectures.forEach(l => l.Selected = l.Id === lect.Id);
                lecture.Selected = forcestate !== undefined ? forcestate : !state;
            }
        });
    }

    public async selectDiffLecture(partId: string, lect: LectureDtoWithOriginals) {
        if (!lect) return;

        this.MutatePartState(partId, draft => {
            if (draft.DiffLectures) {
                const lecture = draft.DiffLectures.find(l => l.Id === lect.Id)!;
                if (lecture) {
                    draft.DiffLectures.forEach(l => l.Selected = l.Id === lect.Id);
                    lecture.Selected = true;
                }
            }
        });
    }


    public updateLectureImage(partId: string, lectureId: string, imageUrl: string) {
        this.MutatePartState(partId, draft => {
            const lect = draft.Data!.Lectures.find(f => f.Id === lectureId);
            if (lect) {
                lect.ImageUrl = imageUrl;
            }
        });
    }

    public async addLecture(data: AddLectureData) {

        try {
            waitRippleservice.setState(true);
            const response = await api.post<LectureDtoWithOriginals>(endPoints.ADD_LECT(data.partId), data);

            if (response && response.status == 200 && response.data) {
                const newLect = response.data;
                this.MutatePartState(data.partId, (draft) => {
                    draft.Data!.Lectures.push(newLect)

                });
            }
        } finally {
            waitRippleservice.setState(false);
        }
    }

    public async copyLecture(data: CopyLectureCommand) {

        try {
            waitRippleservice.setState(true);
            const response = await api.post<LectureDtoWithOriginals>(endPoints.COPY_LECT(data.destCoursepart), data);

            if (response && response.status == 200 && response.data) {
                const newLect = response.data;

                this.MutatePartState(newLect.PartId, (draft) => {
                    draft.Data!.Lectures.push(newLect)
                });
            }
        } finally {
            waitRippleservice.setState(false);
        }


    }

    public async deleteLecture(partId: string, lect: LectureDtoWithOriginals) {

        try {
            waitRippleservice.setState(true);
            await api.delete<void>(endPoints.DELETE_LECT(partId, lect.Id));

            this.MutatePartState(partId, (draft) => {
                draft.Data!.Lectures = draft.Data!.Lectures.filter(l => l.Id !== lect.Id);
            });
        } finally {
            waitRippleservice.setState(false);
        }
    }

    public async saveLecturesOrder(partId: string) {

        try {
            waitRippleservice.setState(true);
            const atom = onePartatom(partId);
            if (atom) {
                let partData = await store.get(atom);
                if (partData && partData.Data) {
                    const lectIds = partData.Data.Lectures.map(l => l.Id)
                    await api.post<void>(endPoints.REORDER_LECT(partId), lectIds);

                    this.MutatePartState(partId, (draft) => {
                        draft.Data!.Dirty = true;
                    });
                }
            }
        } finally {
            waitRippleservice.setState(false);
        }
    }

    public async updateLectureText(partId: string, cmd: LectureUpdatTextsCommand) {
        this.MutatePartState(partId, (draft) => {
            const lect = draft.Data?.Lectures.find(l => l.Id === cmd.LectureId);
            if (lect) {
                lect.Name = cmd.Name,
                    lect.Description = cmd.Description;
            }
        })
    }


    /* Files ******************************** */

    async addFileGroup(data: { name: string, partId: string }) {
        const nameDto = {
            Value: data.name
        }
        const response = await api.post<FilesGroupModel>(endPoints.ADD_FILEGROUP(data.partId), nameDto)
        if (response && response.data) {
            this.MutatePartState(data.partId, (draft) => {
                draft.Data!.FileGroups = [response.data, ...draft.Data!.FileGroups]
            });
        }
    }

    async deleteFileGroup(data: { groupId: string, partId: string }) {

        const response = await api.delete<FilesGroupModel>(endPoints.DELETE_FILEGROUP(data.partId, data.groupId))
        if (response && response.data) {
            this.MutatePartState(data.partId, (draft) => {
                const group = draft.Data!.FileGroups.find(g => g.Id === data.groupId);
                const defaultGroup = draft.Data!.FileGroups.find(g => g.IsDefault);

                if (group) {
                    group.Files.forEach(l => defaultGroup!.Files.push(l));
                }
                draft.Data!.FileGroups = draft.Data!.FileGroups.filter(g => g.Id !== data.groupId);
            });

        }
    }

    renameFileGroup(data: { name: string; groupId: string; partId: string; }) {
        this.MutatePartState(data.partId, draft => {
            const group = draft.Data?.FileGroups.find(g => g.Id === data.groupId);
            if (group) {
                group.Name = data.name;
            }
        });
        this.saveFileGroups(data.partId);
    }

    public async saveFileGroups(partId: string) {

        const atom = onePartatom(partId);
        if (atom) {
            let partData = await store.get(atom);
            if (partData && partData.Data) {
                await api.post<boolean>(endPoints.SET_FILEGROUP_DATA(partId), partData.Data.FileGroups);
                this.MutatePartState(partId, (draft) => {
                    draft.Data!.Dirty = true;
                });
                return partData.Data!.FileGroups;
            }
        }
        return undefined;
    }

    public addFile(data: AddFileModel, userId: string, partId: string, groupId?: string) {

        const uploadData = fileUploadservice.uploadFile(null, userId, data.file);

        if (uploadData) {
            uploadData.promise.then((res) => {
                this.handleFileUploaded(res, data.file, data.description, partId, groupId);
            });
            return uploadData;
        } else {
            return null
        }

    }

    private async handleFileUploaded(res: CompleteMultipartUploadCommandOutput, file: File, description: string, partId: string, groupId?: string) {

        try {
            waitRippleservice.setState(true);

            const data: AddFileRequest = {
                Description: description,
                FileName: file.name,
                GroupId: groupId,
                PartId: partId,
                TempFileId: res.Key!
            }

            const response = await api.post<FileModel>(endPoints.ADD_FILE(partId), data)

            if (response && response.data) {
                this.MutatePartState(partId, (draft) => {
                    let group = draft.Data!.FileGroups.find(g => g.Id === groupId);
                    if (!group) {
                        group = draft.Data!.FileGroups.find(g => g.IsDefault);
                    }

                    group?.Files.push(response.data);

                });
            }
        } finally {
            waitRippleservice.setState(false);
        }

    }

    public async deleteFile(partId: string, fileId: string, groupId: string) {

        const response = await api.delete<void>(endPoints.DELETE_FILE(partId, fileId));
        if (response.status < 300) {

            this.MutatePartState(partId, (draft) => {
                let group = draft.Data!.FileGroups.find(g => g.Id === groupId);
                if (!group) {
                    group = draft.Data!.FileGroups.find(g => g.IsDefault);
                }
                group!.Files = group!.Files.filter(f => f.Id !== fileId);
            });

        }

    }

    public async updateFileDescription(partId: string, fileId: string, groupId: string, text: string) {


        this.MutatePartState(partId, draft => {
            let group = draft.Data!.FileGroups.find(g => g.Id === groupId);
            if (!group) {
                group = draft.Data!.FileGroups.find(g => g.IsDefault);
            }
            const file = group!.Files.find(f => f.Id === fileId);
            if (file) {
                file.Description = text;
            }
        });

        this.saveFileGroups(partId);

    }

    /* Links ******************************** */

    async addLinkGroup(data: { name: string, partId: string }) {
        const nameDto = {
            Value: data.name
        }
        const response = await api.post<LinksGroupModel>(endPoints.ADD_LINKGROUP(data.partId), nameDto)
        if (response && response.data) {
            this.MutatePartState(data.partId, (draft) => {
                draft.Data!.LinkGroups = [response.data, ...draft.Data!.LinkGroups]
            });
        }
    }

    async deleteLinkGroup(data: { groupId: string, partId: string }) {

        const response = await api.delete<LinksGroupModel>(endPoints.DELETE_LINKGROUP(data.partId, data.groupId))
        if (response && response.data) {
            this.MutatePartState(data.partId, (draft) => {
                const group = draft.Data!.LinkGroups.find(g => g.Id === data.groupId);
                const defaultGroup = draft.Data!.LinkGroups.find(g => g.IsDefault);

                if (group) {
                    group.Links.forEach(l => defaultGroup!.Links.push(l));
                }
                draft.Data!.LinkGroups = draft.Data!.LinkGroups.filter(g => g.Id !== data.groupId);
            });

        }
    }

    renameLinkGroup(data: { name: string; groupId: string; partId: string; }) {
        this.MutatePartState(data.partId, draft => {
            const group = draft.Data?.LinkGroups.find(g => g.Id === data.groupId);
            if (group) {
                group.Name = data.name;
            }
        });
        this.saveLinkGroups(data.partId);
    }

    public async saveLinkGroups(partId: string) {

        const atom = onePartatom(partId);
        if (atom) {
            let partData = await store.get(atom);
            if (partData && partData.Data) {
                await api.post<boolean>(endPoints.SET_LINKGROUP_DATA(partId), partData.Data.LinkGroups);
                this.MutatePartState(partId, (draft) => {
                    draft.Data!.Dirty = true;
                });
                return partData.Data!.LinkGroups;
            }
        }
        return undefined;
    }


    public async saveOrUpdateLink(partId: string, groupId: string, model: LinkModel) {
        if (!model.Id) {
            const formData: LinkFormDTO = {
                GroupID: groupId,
                Id: model.Id,
                Name: model.Description,
                Url: model.Url,
                Order: 0
            }
            const response = await api.post<LinkModel>(endPoints.ADD_LINK(partId), formData)

            if (response && response.status < 300 && response.data) {

                this.MutatePartState(partId, (draft => {
                    let group = draft.Data!.LinkGroups.find(g => g.Id === groupId);
                    if (!group) {
                        group = draft.Data!.LinkGroups.find(g => g.IsDefault);
                    }
                    group!.Links = [response.data, ...group!.Links];
                }));
            }
        } else {
            this.MutatePartState(partId, (draft) => {
                let group = draft.Data!.LinkGroups.find(g => g.Id === groupId);
                if (!group) {
                    group = draft.Data!.LinkGroups.find(g => g.IsDefault);
                }
                const link = group!.Links.find(f => f.Id === model.Id);
                if (link) {
                    link.Description = model.Description;
                    link.Url = model.Url;
                }
            });

            this.saveLinkGroups(partId);
        }

    }

    public async deleteLink(partId: string, groupId: string, linkId: string) {

        const response = await api.delete<void>(endPoints.DELETE_LINK(partId, linkId));

        if (response.status < 300) {
            this.MutatePartState(partId, (draft) => {
                let group = draft.Data!.LinkGroups.find(g => g.Id === groupId);
                if (!group) {
                    group = draft.Data!.LinkGroups.find(g => g.IsDefault);
                }
                group!.Links = group!.Links.filter(f => f.Id !== linkId);
            });
        }

    }

    public async updateTestInPart(partId: string, testId: string, name: string) {
        this.MutatePartState(partId, (draft) => {
            const test = draft.Data!.Tests.find(t => t.Id === testId);
            if (test) {
                test.Name = name;
                draft.Data!.Dirty = true;
            }
        });


    }

    public async updateTestAuthoringOKInPart(partId: string, testId: string, authoringOk: boolean) {
        this.MutatePartState(partId, (draft) => {
            const test = draft.Data!.Tests.find(t => t.Id === testId);
            if (test) {
                test.AuthoringOk = authoringOk;
                draft.Data!.Dirty = true;
            }
        });


    }

    /* evals ******************************** */

    public async toggeEvalQuestionScore(qid: number, checked: boolean, partId: string) {
        this.MutatePartState(partId, (state) => {
            const question = state.Data?.EvalQuestions.CustomQuestions.find(q => qid === q.Id);
            if (question) {
                question.HaveScores = checked;
            }
        });

        this.saveEvalQuestion(partId, qid);
    }

    public async saveEvalQuestionsOrder(partId: string) {

        const partData = store.get(onePartatom(partId));
        if (!partData.Data) return;

        const evalIds = partData.Data.EvalQuestions.CustomQuestions.map(q => q.Id);
        await api.post(endPoints.EVAL_ORDER_QUESTIONS(partId), evalIds);
    }

    public async addEvalQuestion(type: EvalQuestionType, partId: string) {

        const response = await api.post<EvaluationQuestionDTO>(endPoints.EVAL_ADD_QUESTION(partId, type), null);
        if (response.status < 300 && response.data) {
            this.MutatePartState(partId, part => {
                if (part.Data?.EvalQuestions.CustomQuestions) {
                    part.Data!.EvalQuestions.CustomQuestions.push(response.data);
                }
            });
        }
    }

    public async deleteEvalQuestion(question: EvaluationQuestionDTO, partId: string) {

        const response = await api.delete(endPoints.EVAL_DELETE_QUESTION(partId, question.Id));
        if (response.status < 300) {
            this.MutatePartState(partId, part => {
                if (part.Data?.EvalQuestions.CustomQuestions) {
                    part.Data!.EvalQuestions.CustomQuestions
                        = part.Data!.EvalQuestions.CustomQuestions.filter(q => q.Id !== question.Id);
                }
            });
        }
    }

    public CopyEvalsFromPart(partId: string, srcPartId: string) {
        api.post<EvaluationQuestionDTO[]>(endPoints.EVAL_COPY_FROM_PART(partId, srcPartId), null)
            .then(resp => {
                if (resp.status === 200) {
                    this.MutatePartState(partId, (part) => {
                        part.Data!.EvalQuestions.CustomQuestions = resp.data;
                    })
                }
            })
    }

    public async FindPartsWithCustomEvals(partId: string, query: string) {
        try {
            var response = await api.get<IdAndNameDto[]>(endPoints.EVAL_FIND_PARTS(partId, query));
            if (response.status === 200) {
                return response.data
            }
            return [];
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    public saveEvalOptionsOrder(partId: string, qid: number) {
        const partData = store.get(onePartatom(partId));
        if (!partData.Data) return;
        const question = partData.Data.EvalQuestions.CustomQuestions.find(q => q.Id === qid);
        const data: number[] = question!.AnswerOptions.map(o => o.Id);
        return api.post(endPoints.EVAL_ORDER_OPTIONS(partId, qid), data);
    }

    public saveOptionText(partId: string, text: string, optionId: number, questionId: number) {

        this.MutatePartState(partId, (part) => {
            const question = part.Data!.EvalQuestions.CustomQuestions.find(q => q.Id === questionId);
            if (question) {
                const option = question.AnswerOptions.find(o => o.Id === optionId);
                if (option) {
                    option.AnswerText = text;
                }
            }
        })

        this.saveEvalQuestion(partId, questionId);
    }

    saveEvalQuestionText(partId: string, text: string, questionId: number) {
        this.MutatePartState(partId, (part) => {
            const question = part.Data!.EvalQuestions.CustomQuestions.find(q => q.Id === questionId);
            if (question) {
                question.QuestionText = text;
            }
        })

        this.saveEvalQuestion(partId, questionId);
    }

    private saveEvalQuestion(partId: string, qid: number) {
        const atom = onePartatom(partId);
        if (atom) {
            const part = store.get(atom);
            const question = part.Data!.EvalQuestions.CustomQuestions.find(q => q.Id === qid);
            api.put(endPoints.EVAL_QUESTION_SAVE(partId, qid), question);
        }
    }

    public async deleteEvalOption(partId: string, optionid: number, qId: number) {

        const response = await api.delete(endPoints.EVAL_DELETE_OPTION(partId, qId, optionid));
        if (response && response.status < 300) {
            this.MutatePartState(partId, partData => {
                const question = partData.Data?.EvalQuestions.CustomQuestions.find(q => q.Id === qId);
                if (question) {
                    question.AnswerOptions = question.AnswerOptions.filter(o => o.Id !== optionid);
                }
            })
        }
    }

    public getNextPartForPublish() {
        const parts = store.get(partsListAtom);
        const part = parts.find(p => p.Dirty === true);
        if (part) return part.Id;

        return null;

    }

    public async addEvalOption(partId: string, qId: number) {

        const atom = onePartatom(partId);
        if (atom) {
            let partData = store.get(atom);
            const question = partData.Data?.EvalQuestions.CustomQuestions.find(q => q.Id === qId);
            if (question) {
                const response = await api.post<EvalEditAnswerOptionDTO>(endPoints.EVAL_CREATE_OPTION(partId, qId, question.HaveScores));
                if (response && response.status < 300) {
                    this.MutatePartState(partId, partData => {
                        const question = partData.Data?.EvalQuestions.CustomQuestions.find(q => q.Id === qId);
                        if (question) {
                            question.AnswerOptions = [...question.AnswerOptions, response.data];
                        }
                    })
                }
            }
        }

    }

    enumerateOptions(partId: string, qId: number, sortingUp: boolean) {

        this.MutatePartState(partId, partData => {
            const question = partData.Data?.EvalQuestions.CustomQuestions.find(q => q.Id === qId);
            if (question) {
                if (sortingUp) {
                    const len = question.AnswerOptions.length;
                    question.AnswerOptions.forEach((o, i) => o.Score = len - i);
                }
                else {
                    question.AnswerOptions.forEach((o, i) => o.Score = i + 1);
                }
            }
        });

        this.saveEvalQuestion(partId, qId);
    }

    setOptionScore(partId: string, qId: number, optionId: number, score: number) {
        this.MutatePartState(partId, partData => {
            const question = partData.Data?.EvalQuestions.CustomQuestions.find(q => q.Id === qId);
            if (question) {
                const option = question.AnswerOptions.find(o => o.Id === optionId);
                if (option) {
                    option.Score = score;
                }
            }
        });

        this.saveEvalQuestion(partId, qId);
    }

    public async addOriginalFile(lectureId: string, partId: string, postData: LectureFilePostDto) {
        const response = await api.post<LectureFileDto>(endPoints.ORIGINAL_FILE(lectureId), postData);
        if (response.status === 200) {
            this.MutatePartState(partId, (draft => {
                const lect = draft.Data?.Lectures.find(l => l.Id === lectureId);
                if (lect) {
                    lect.OriginalFiles = [...(lect.OriginalFiles || []), response.data];
                }
            }))
        }
    }


    public async deleteOriginalFile(lectureId: string, partId: string, fileId: number) {

        const response = await api.delete<void>(endPoints.ORIGINAL_FILE_DELETE(lectureId, fileId));
        if (response.status === 200) {
            this.MutatePartState(partId, (draft => {
                const lect = draft.Data?.Lectures.find(l => l.Id === lectureId);
                if (lect && lect.OriginalFiles) {
                    lect.OriginalFiles = lect.OriginalFiles.filter(f => f.Id !== fileId);
                }
            }))
        }
    }

    public async getTextDiff(partId: string) {
        try {
            const response = await api.get<TextDiffResponse>(endPoints.TEXT_DIFF(partId));
            if (response.status === 200) {
                return response.data;
            }

            throw new Error("no data. Status != 200");

        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    public async getDiffFiles(partId: string) {
        try {
            const response = await api.get<FileDiffResponse>(endPoints.FILES_DIFF(partId));
            if (response.status === 200) {
                this.MutatePartState(partId, draft => {
                    draft.DiffFiles = response.data;
                });

                return response.data;
            }
            throw new Error("no data. Status != 200");

        } catch (error) {
            console.error(error);
            return undefined;
        }

    }

    public async getDiffTests(partId: string) {
        try {
            const response = await api.get<TestsDiffResponse>(endPoints.TESTS_DIFF(partId));
            if (response.status === 200) {
                this.MutatePartState(partId, draft => {
                    draft.DiffTests = response.data;
                });

                return response.data;
            }
            throw new Error("no data. Status != 200");

        } catch (error) {
            console.error(error);
            return undefined;
        }

    }

    public async getDiffLinks(partId: string) {
        try {
            const response = await api.get<LinkDiffResponse>(endPoints.LINKS_DIFF(partId));
            if (response.status === 200) {
                this.MutatePartState(partId, draft => {
                    draft.DiffLinks = response.data;
                });

                return response.data;
            }
            throw new Error("no data. Status != 200");

        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    public async getDiffLectures(partId: string) {
        try {
            const response = await api.get<LectureDtoWithOriginals[]>(endPoints.LECTURES_DIFF(partId));
            if (response.status === 200) {
                this.MutatePartState(partId, draft => {
                    draft.DiffLectures = response.data;
                });

                return response.data;
            }
            throw new Error("no data. Status != 200");

        } catch (error) {
            console.error(error);
            return undefined;
        }

        
    }

    private addToLastData(part: CoursePartEditModel, userId: string) {
        lastDataService.addToLastCourseParts({
            dirty: part.Data!.Dirty,
            connectedToCourse: part.Data!.ConnectedWithCourse,
            userid: userId,
            id: part.Data!.Id,
            name: part.Data?.Name || "-"
        });
    }






}

const coursePartService = new CoursePartService();
export default coursePartService;